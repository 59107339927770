import {
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  Box,
  Button,
} from "@mui/material";
import React from "react";
import HeroImage from "../../../assets/Images/NormalUserHeroImage.png";
import brand1 from "../../../assets/Images/NormalUserSCR1.png";
import brand2 from "../../../assets/Images/NormalUserSCR2.png";
import brand3 from "../../../assets/Images/NormalUserSCR3.png";
import brand4 from "../../../assets/Images/NormalUserSCR4.png";
import NormalUserTabComponent1 from "./NormalUserTabComponent1";
import Test from "../../Common/test";
import { Container } from "@mui/system";
import { NavLink } from "react-router-dom";
const NormalUser = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  React.useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      {" "}
      <Grid container className="positionRelative heroImageGrid">
        <Grid item xs={12} md={12} sx={{ height: "100%", width: "100%" }}>
          <img src={HeroImage} className="heroSectionClass widh100" />
        </Grid>
        <Grid
          item
          xs={12}
          className="positionAbsolute BuisnessOwnerHeroSectionDiv"
        >
          <Grid container className="heroTextgap">
            <Grid item xs={12}>
              <Typography className="BuisnessOwnerHeadingFontSize">
                Showcase Your product or Talent
              </Typography>
              <Typography className="BuisnessOwnerHeadingFontSize">
                on a Digital Billboard
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div>
                {/* <Typography className=" heroSectionParaMarginBottom BuisnessOwnerParaFontSize2">
                  Deliver more value to your clients by building integrated
                  campaigns that
                </Typography> */}
                <Typography className=" BuisnessOwnerParaFontSize2">
                  Get Seen On the Big Screen In Minutes
                </Typography>
              </div>
            </Grid>
            <Box
              sx={{
                textAlign: "center",
                width: "100%",
              }}
            >
              <NavLink to="/contact_us" style={{ textDecoration: "none" }}>
                <Button className="heroSectionButton">Request A Demo</Button>
              </NavLink>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Container maxWidth="xl">
        <Grid
          container
          className={
            isMd ? "widh90 marginTop50px gap20px" : "widh90 marginTop100px"
          }
          justifyContent="space-between"
        >
          <Grid item xs={12} md={5.5}>
            <Grid
              container
              gap="3rem"
              direction="column"
              justifyContent="center"
              sx={{ height: "100%" }}
            >
              <Typography className="heading1">
                {" "}
                Create and Post Creative Content
              </Typography>
              <Typography className="subHeading1">
                As an Individual, you can use the postMyAd Platform to create,
                publish and manage your ads or social messages. as a Social
                Media Influencer/ Content Creator, you need not be limited to
                Facebook or YouTube - you can now step up your game by being
                visible on a screen much bigger than instagram - literally.
                Families and friends can pamper their loved ones by posting
                birthday and anniversary greetings or congratulatory messages
                with the click of a button.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={5.5}>
            <img src={brand2} alt="agencyCardImage" className="widh100 " />
          </Grid>
        </Grid>
        <Grid
          container
          className={
            isMd ? "widh90 marginTop50px gap20px" : "widh90 marginTop100px"
          }
          justifyContent="space-between"
        >
          <Grid item xs={12} md={5.5} order={{ xs: 2, md: 1 }}>
            <img src={brand4} alt="agencyCardImage" className="widh100 " />
          </Grid>
          <Grid item xs={12} md={5.5} order={{ xs: 1, md: 1 }}>
            <Grid
              container
              gap="3rem"
              direction="column"
              justifyContent="center"
              sx={{ height: "100%" }}
            >
              <Typography className="heading1">
                {" "}
                Wide geographical coverage
              </Typography>
              <Typography className="subHeading1">
                With our wide network of digital billboards, we provide you with
                a unique opportunity to Break geographic boundaries and increase
                target audience and ensure you get the attention You strive for.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          className={
            isMd ? "widh90 marginTop50px gap20px" : "widh90 marginTop100px"
          }
          justifyContent="space-between"
        >
          <Grid item xs={12} md={5.5}>
            <Grid
              container
              gap="3rem"
              direction="column"
              justifyContent="center"
              sx={{ height: "100%" }}
            >
              <Typography className="heading1">
                {" "}
                High Engagement Rates
              </Typography>
              <Typography className="subHeading1">
                Run ad campaigns using Images, videoes or rich media formats.
                All our strategically placed billboards offer high Definition/
                4K display which ensures that audience is captivated with the
                quality of content displayed. So engage your audience with
                PostMyAd.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={5.5}>
            <img src={brand3} alt="agencyCardImage" className="widh100 " />
          </Grid>
        </Grid>
        <Grid
          container
          className={
            isMd ? "widh90 marginTop50px gap20px" : "widh90 marginTop100px"
          }
          justifyContent="space-between"
        >
          <Grid item xs={12} md={5.5} order={{ xs: 2, md: 1 }}>
            <img src={brand1} alt="agencyCardImage" className="widh100 " />
          </Grid>
          <Grid item xs={12} md={5.5} order={{ xs: 1, md: 1 }}>
            <Grid
              container
              gap="3rem"
              direction="column"
              justifyContent="center"
              sx={{ height: "100%" }}
            >
              <Typography className="heading1">
                {" "}
                Cost-Effective Advertising With Measurable Impact
              </Typography>
              <Typography className="subHeading1">
                PostMyAd is a Smart advertising tool that requires minimum time
                and money and tracks the effectiveness of your Ads and ad
                campaigns.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <NormalUserTabComponent1 />
      </Container>
      <Test />
    </>
  );
};

export default NormalUser;
