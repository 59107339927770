import React from "react";
import {
  Typography,
  Grid,
  Breadcrumbs,
  Tabs,
  Tab,
  DialogContent,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import PropTypes from "prop-types";
import TabImage1 from "../../../assets/Images/Agencytab1.png";
import TabImage2 from "../../../assets/Images/Agencytab2.png";
import TabImage3 from "../../../assets/Images/Agencytab3.png";
import TabImage4 from "../../../assets/Images/Agencytab4.png";
import TabImage5 from "../../../assets/Images/SC2.png";

import { Box } from "@mui/system";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children} </div>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const AgencyTab = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  console.log(isMd);
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  console.log(isMd);
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const tabPanelContent = [
    {
      title: "Manage time slot and location to showcase the ad.",
      img1: TabImage1,
    },
    {
      title: "Curate and upload ad for client. ",
      img1: TabImage2,
    },
    {
      title: "Invoicegeneration and payment",
      img1: TabImage3,
    },
    {
      title: "Ad showcased on pre-designated day and time.",
      img1: TabImage4,
    },
    {
      title: "Real time audience feedback generated and shared. ",
      img1: TabImage5,
    },
  ];

  return (
    <div>
      <Typography
        className="vilotColorHeading "
        sx={{ margin: "10rem 0 4rem 0" }}
      >
        How Its Work?
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isSm ? (
          <Tabs
            value={value}
            onChange={handleChange}
            className="TabStyleAddCampaign"
            sx={{
              marginBottom: "5rem",
              width: "100%",
              "& .css-ptiqhd-MuiSvgIcon-root": {
                fontSize: "3rem",
              },
            }}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            aria-label="scrollable auto tabs example"
            // centered={true}
          >
            <Tab label="Schedule" className="TabChangesCampaign" />
            <Tab label="Upload Ad" className="TabChangesCampaign" />
            <Tab label="Pay" className="TabChangesCampaign" />
            <Tab label="Display Ad" className="TabChangesCampaign" />
            <Tab label="Get Analytics" className="TabChangesCampaign" />
          </Tabs>
        ) : (
          <Tabs
            value={value}
            onChange={handleChange}
            className="TabStyleAddCampaign"
            sx={{
              marginBottom: "5rem",
              width: "100%",
              "& .css-ptiqhd-MuiSvgIcon-root": {
                fontSize: "3rem",
              },
            }}
            centered={true}
          >
            <Tab label="Schedule" className="TabChangesCampaign" />
            <Tab label="Upload Ad" className="TabChangesCampaign" />
            <Tab label="Pay" className="TabChangesCampaign" />
            <Tab label="Display Ad" className="TabChangesCampaign" />
            <Tab label="Get Analytics" className="TabChangesCampaign" />
          </Tabs>
        )}
        {tabPanelContent.map((a, i) => (
          <TabPanel value={value} index={i}>
            <Typography
              className="adverTiseCardTypography"
              sx={{
                width: "80%",
                margin: "auto",
                textAlign: "center",
                marginBottom: "1rem",
              }}
            >
              {a.title}
            </Typography>

            <Grid container justifyContent="center">
              <div
                className="flexBoxClass"
                style={{
                  width: "90%",
                  height: "100%",
                  gap: "3rem",
                }}
              >
                <img
                  src={a.img1}
                  className={isMd ? "widh100" : ""}
                  style={{
                    width: "80%",
                    height: "100%",
                    borderRadius: "1.5rem",
                  }}
                />
              </div>
            </Grid>
          </TabPanel>
        ))}
      </div>
    </div>
  );
};

export default AgencyTab;
