import React from "react";
import { Grid, Typography, useTheme, useMediaQuery } from "@mui/material";
const Test = ({ noMargin }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div className={noMargin ? "" : "marginTop100px"}>
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            backgroundColor: "#F5F9FA",
          }}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            className="wantToWorkGrid"
            sx={{
              // height: "40rem",
              backgroundColor: "#F8F9FA",
            }}
          >
            <Grid item xs={12} style={{ height: "100%", width: "100%" }}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{
                  height: "100%",
                  width: "100%",
                }}
              >
                <Typography
                  className={
                    isMd
                      ? "bigDarkHeadingTypography lightGreyColor textAlignCentre fontSize40px "
                      : "bigDarkHeadingTypography lightGreyColor "
                  }
                  sx={{
                    width: "80%",
                    marginInline: "2rem",
                  }}
                >
                  WANT TO BE A BILLBOARD PARTNER?
                </Typography>
                {/* <Typography
                  className={
                    isMd
                      ? "bigDarkHeadingTypography lightGreyColor textAlignCentre marginBottom10px fontSize40px "
                      : "bigDarkHeadingTypography lightGreyColor marginBottom10px"
                  }
                  sx={{
                    width: "50%",

                    marginInline: "2rem",
                  }}
                ></Typography> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            backgroundColor: "#1D1D1E",
          }}
        >
          <Grid
            container
            alignItems="center"
            className="wantToWorkGrid"
            // sx={{ height: "40rem" }}
          >
            {" "}
            <Grid container direction="column">
              <Typography
                sx={{
                  color: "#FFFFFF",
                  marginBottom: "3rem",
                  marginInline: "4rem",
                }}
                className={
                  isMd
                    ? "WhiteColorTypography fontSize48px textAlignCentre"
                    : "WhiteColorTypography fontSize48px "
                }
              >
                GET IN TOUCH:
              </Typography>
              <Typography
                sx={{
                  color: "#FFFFFF",
                  lineHeight: "3.6rem",
                  marginInline: "4rem",
                }}
                className={
                  isMd
                    ? "WhiteColorTypography fontSize24px textAlignCentre"
                    : "WhiteColorTypography fontSize24px "
                }
              >
                hello@postmyad.ai
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Test;
