import React from "react";
import { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
const PrivacyPolicies = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <Grid
        container
        sx={{ marginTop: "72px", padding: "5rem 0" }}
        className="widh90"
        gap="4rem"
      >
        <Grid item xs={12} sx={{ marginBottom: "3rem" }}>
          <Typography className="Content_policy_typography">
            Privacy Policy
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            className="Content_policy_subHeading"
            sx={{ marginBottom: "3rem" }}
          >
            Basic Purpose:
          </Typography>
          <Typography
            className="Content_policy_Paragraph_typography"
            sx={{ marginBottom: "2rem" }}
          >
            At SAPS.ai, we believe you should always know what data we collect
            from you and how we use it, and that you should have meaningful
            control over both. We want to empower you to make the best decisions
            about the information that you share with us.
          </Typography>
          <Grid container gap="2rem">
            <Typography className="Content_policy_Paragraph_typography2">
              We encourage you to read this policy in full, but here are a few
              key things we hope you take away from it:
            </Typography>
            <Typography
              className="Content_policy_Paragraph_typography2"
              sx={{ paddingLeft: "2rem" }}
            >
              1. SAPS.ai is a public domain and its content is immediately
              viewable and searchable by anyone around the world. You can also
              use SAPS.ai under a pseudonym if you prefer not to use your name.
            </Typography>
            <Typography
              className="Content_policy_Paragraph_typography2"
              sx={{ paddingLeft: "2rem" }}
            >
              2. When you use SAPS.ai, even if you’re just looking at content,
              we receive some personal information from you like your profile,
              the type of device you’re using and your IP address. You can
              choose to share additional information with us like your address
              book contacts and/or a public profile. We use this information for
              things like keeping your account secure and showing you more
              relevant content and ads, etc.
            </Typography>
            <Typography
              className="Content_policy_Paragraph_typography2"
              sx={{ paddingLeft: "2rem" }}
            >
              3. We give you control through your settings to limit the data we
              collect from you and how we use it, and to control things like
              account security, marketing preferences and address book contacts
              you’ve uploaded to SAPS.ai. You can also download information you
              have shared on SAPS.ai.
            </Typography>
            <Typography
              className="Content_policy_Paragraph_typography2"
              sx={{ paddingLeft: "2rem" }}
            >
              4. In addition to information you share with us, we use your
              activity, content you’ve read or liked and other information to
              determine what type of content you’re interested in, your age, the
              languages you speak, and other signals to show you more relevant
              content. We give you transparency into that information, and you
              can modify or correct it at any time.
            </Typography>
            <Typography
              className="Content_policy_Paragraph_typography2"
              sx={{ paddingLeft: "2rem" }}
            >
              If you have questions about this policy, how we collect or process
              your personal data, or anything else related to our privacy
              practices, we want to hear from you. You can contact us at any
              time.
            </Typography>
          </Grid>
        </Grid>
        <Grid xs={12}>
          <Typography
            className="Content_policy_subHeading"
            sx={{ marginBottom: "3rem" }}
          >
            1. Information You Share With Us
          </Typography>
          <Typography className="Content_policy_Paragraph_typography2">
            At SAPS.ai, we believe you should always know what data we collect
            from you and how we use it, and that you should have meaningful
            control over both. We want to empower you to make the best decisions
            about the information that you share with us.
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography
            className="Content_policy_subHeading"
            sx={{ marginBottom: "3rem" }}
          >
            a. Basic Account Information
          </Typography>
          <Typography className="Content_policy_Paragraph_typography2">
            You have to create an account to use our services. If you do choose
            to create an account, you must provide us with some personal data so
            that we can provide our services to you. On SAPS.ai this includes a
            display name, a username (for example, @SAPS.ai Influencer), a
            password, and an email address or phone number. Your display name
            and username are always public, but you can use either your real
            name or a pseudonym. If you choose to create a professional account,
            you must also provide us with your professional category, and may
            provide us with additional information, including street address,
            contact email address, and contact phone number, all of which will
            always be public. In case you transact on our platform, you will
            have to provide Tax IDs and Goods and Service Tax IDs for regulatory
            and statutory compliances. You can also create and manage multiple
            SAPS.ai accounts for example to provide different types of content.
          </Typography>
        </Grid>

        <Grid xs={12}>
          <Typography
            className="Content_policy_subHeading"
            sx={{ marginBottom: "3rem" }}
          >
            b. Public Information
          </Typography>
          <Typography
            className="Content_policy_Paragraph_typography2"
            sx={{ marginBottom: "2rem" }}
          >
            Most activity on SAPS.ai is public, including your profile
            information, your display language, when you created your account,
            and your content and certain information about your Content like the
            date, time, and application and version of SAPS.ai you are using.
            When you share audio or visual content on our service we may analyze
            that data to operate our services, for example by providing audio
            transcription. The lists you create, people you follow and who
            follow you, and Content you Like are also public. If you like,
            respond to or otherwise publicly engage with an ad on our services,
            that advertiser might thereby learn information about you associated
            with the ad with which you engaged such as characteristics of the
            audience the ad was intended to reach. Broadcasts you create are
            public along with when you created them. Your engagement with
            broadcasts, including viewing, listening, commenting, speaking,
            reacting to, or otherwise participating in them, on SAPS.ai, is
            public along with when you took those actions. Any engagement with
            another account’s broadcast will remain part of that broadcast for
            as long as it remains on our services. Information posted about you
            by other people who use our services may also be public.
          </Typography>
          <Typography className="Content_policy_Paragraph_typography2">
            You are responsible for your content and other information you
            provide through our services, and you should think carefully about
            what you make public, especially if it is sensitive information. If
            you update your public information on SAPS.ai, such as by deleting
            Content or deactivating your account, we will reflect your updated
            content on SAPS.ai., SAPS.ai for iOS, and SAPS.ai for Android. By
            publicly posting content, you are directing us to disclose that
            information as broadly as possible, including through our APIs, and
            directing those accessing the information through our APIs to do the
            same. To facilitate the fast global dissemination of Content to
            people around the world, we use technology like application
            programming interfaces (APIs) and embeds to make that information
            available to websites, apps, and others for their use - for example,
            displaying Content on a news website or analyzing what people say on
            SAPS.ai. We generally make this content available in limited
            quantities for free and charge licensing fees for large-scale
            access. We have standard terms that govern how this data can be
            used, and a compliance program to enforce these terms. But these
            individuals and companies are not affiliated with SAPS.ai, and their
            offerings may not reflect updates you make on SAPS.ai.
          </Typography>
        </Grid>

        <Grid xs={12}>
          <Typography
            className="Content_policy_subHeading"
            sx={{ marginBottom: "3rem" }}
          >
            c. Contact Information and Address Books
          </Typography>
          <Typography className="Content_policy_Paragraph_typography2">
            We use your contact information, such as your email address or phone
            number, to authenticate your account and keep it - and our services
            - secure, and to help prevent spam, fraud, and abuse. Subject to
            your settings, we also use contact information to enable certain
            account features (for example, for login verification), to send you
            information about our services, and to personalize our services,
            including ads. If you provide us with your phone number, you agree
            to receive text messages from SAPS.ai to that number as your
            country’s laws allow. SAPS.ai also uses your contact information to
            market to you as your country’s laws allow, and to help others find
            your account if your settings permit, including through third- party
            services and client applications. You can use your settings for
            email and mobile notifications to control notifications you receive
            from SAPS.ai. You can also unsubscribe from a notification by
            following the instructions contained within the notification or
            here. You can choose to upload and sync your address book on SAPS.ai
            so that we can help you find and connect with people you know and
            help others find and connect with you. We also use this information
            to better recommend content to you and others. If you email us, we
            will keep the content of your message, your email address, and your
            contact information to respond to your request.
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography
            className="Content_policy_subHeading"
            sx={{ marginBottom: "3rem" }}
          >
            d. Direct Messages and Non-Public Communications
          </Typography>
          <Typography className="Content_policy_Paragraph_typography2">
            We provide certain features that may let you communicate more
            privately or control who sees your content. For example, you can use
            Direct Messages in future to have non-public conversations on
            SAPS.ai. When you communicate with others by sending or receiving
            Direct Messages, we will store and process your communications and
            information related to them. This includes link scanning for
            malicious content, link shortening to http://t.co URLs, detection of
            spam, abuse and prohibited images, and use of reported issues. We
            also use information about whom you have communicated with and when
            (but not the content of those communications) to better understand
            the use of our services, to protect the safety and integrity of our
            platform, and to show more relevant content. We share the content of
            your Direct Messages with the people you’ve sent them to; we do not
            use them to serve you ads. When you use features like Direct
            Messages to communicate, remember that recipients have their own
            copy of your communications on SAPS.ai - even if you delete your
            copy of those messages from your account - which they may duplicate,
            store, or re-share.
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography
            className="Content_policy_subHeading"
            sx={{ marginBottom: "3rem" }}
          >
            e. Payment Information
          </Typography>
          <Typography className="Content_policy_Paragraph_typography2">
            You may provide us with payment information, including your credit
            or debit card number, card expiration date, CVV code, and billing
            address, in order to purchase advertising or other offerings
            provided as part of our services. If you make a payment or send
            money using SAPS.ai features or services, including through an
            intermediary, we may receive information about your transaction such
            as when it was made or when a subscription is set to expire or
            auto-renew.
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography
            className="Content_policy_subHeading"
            sx={{ marginBottom: "3rem" }}
          >
            f. Additional Information We Receive About You
          </Typography>
          <Typography className="Content_policy_Paragraph_typography2">
            We receive certain information when you use our services or other
            websites or mobile applications that include our content, and from
            third parties including advertisers. Like the information you share
            with us, we use the data below to operate our services.
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography
            className="Content_policy_subHeading"
            sx={{ marginBottom: "3rem" }}
          >
            a. Location Information
          </Typography>
          <Typography className="Content_policy_Paragraph_typography2">
            We require information about your signup and current location, which
            we get from signals such as your IP address or device settings, to
            securely and reliably set up and maintain your account and to
            provide our services to you. Subject to your settings, we may
            collect, use, and store additional information about your location -
            such as your current precise position or places where you’ve
            previously used SAPS.ai - to operate or personalize our services
            including with more relevant content like local trends, stories,
            ads, and suggestions for people to follow
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography
            className="Content_policy_subHeading"
            sx={{ marginBottom: "3rem" }}
          >
            b. Links / QR Codes
          </Typography>
          <Typography className="Content_policy_Paragraph_typography2">
            In order to operate our services, we keep track of how you interact
            with links/QR codes across our services. This includes links in
            emails we send you and links/QR codes in content that appear on
            screens, other websites or mobile applications. If you click on an
            external link/QR codes or ad on our services, that advertiser or
            website operator might figure out that you came from SAPS.ai and/or
            location of screen, along with other information associated with the
            ad you clicked such as characteristics of the audience it was
            intended to reach. They may also collect other personal data from
            you, such as cookie identifiers or your IP address.
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography
            className="Content_policy_subHeading"
            sx={{ marginBottom: "3rem" }}
          >
            c. Cookies
          </Typography>
          <Typography className="Content_policy_Paragraph_typography2">
            A cookie is a small piece of data that is stored on your computer or
            mobile device. Like many websites, we use cookies and similar
            technologies to collect additional website usage data and to operate
            our services. Cookies are not required for many parts of our
            services such as searching and looking at public profiles. Although
            most web browsers automatically accept cookies, many browsers’
            settings can be set to decline cookies or alert you when a website
            is attempting to place a cookie on your computer. However, some of
            our services may not function properly if you disable cookies. When
            your browser or device allows it, we use both session cookies and
            persistent cookies to better understand how you interact with our
            services, to monitor aggregate usage patterns, and to personalize
            and otherwise operate our services such as by providing account
            security, personalizing the content we show you including ads, and
            remembering your language preferences. We do not support the Do Not
            Track browser option. You can learn more about how we use cookies
            and similar technologies here.
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography
            className="Content_policy_subHeading"
            sx={{ marginBottom: "3rem" }}
          >
            d. Log Data
          </Typography>
          <Typography
            className="Content_policy_Paragraph_typography2"
            sx={{ marginBottom: "2rem" }}
          >
            We receive information when you view content on or otherwise
            interact with our services, which we refer to as “Log Data,” even if
            you have not created an account. For example, when you visit our
            websites, sign into our services, interact with our email
            notifications, use your account to authenticate to a third-party
            service, or visit a third-party service that includes SAPS.ai
            content, we may receive information about you. This Log Data
            includes information such as your IP address, browser type,
            operating system, the referring web page, pages visited, location,
            your mobile carrier, device information (including device and
            application IDs), search terms (including those not submitted as
            queries), and cookie information. We also receive Log Data when you
            click on, view, or interact with links on our services, including
            when you install another application through SAPS.ai. We use Log
            Data to operate our services and ensure their secure, reliable, and
            robust performance. For example, we use Log Data to protect the
            security of accounts and to determine what content is popular on our
            services. We also use this data to improve the content we show you,
            including ads and to improve the effectiveness of our own marketing.
          </Typography>
          <Typography className="Content_policy_Paragraph_typography2">
            We use information you provide to us and data we receive, including
            Log Data and data from third parties, to make inferences like what
            topics you may be interested in, how old you are, and what languages
            you speak. This helps us better promote and design our services for
            you and personalize the content we show you, including ads.
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography
            className="Content_policy_subHeading"
            sx={{ marginBottom: "3rem" }}
          >
            e. SAPS.ai for Web Data
          </Typography>
          <Typography className="Content_policy_Paragraph_typography2">
            When you view our content on third-party websites that integrate
            SAPS.ai content such as embedded timelines or content screens, we
            may receive Log Data that includes the web page you visited. We use
            this information to better understand the use of our services, to
            protect the safety and integrity of our platform, and to show more
            relevant content, including ads. We do not associate this web
            browsing history with your name, email address, phone number, or
            username, and we delete, obfuscate, or aggregate it after no longer
            than 150 days.
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography
            className="Content_policy_subHeading"
            sx={{ marginBottom: "3rem" }}
          >
            f. Advertisers and Other Ad Partners
          </Typography>
          <Typography className="Content_policy_Paragraph_typography2">
            Advertising revenue allows us to support and improve our services.
            We use the information described in this Privacy Policy to help make
            our advertising more relevant to you, to measure its effectiveness,
            and to help recognize your devices to serve you ads on and off of
            SAPS.ai. Our ad partners and affiliates share information with us
            such as browser cookie IDs, mobile device IDs, hashed email
            addresses, demographic or interest data, and content viewed or
            actions taken on a website or app. Some of our ad partners,
            particularly our advertisers, also enable us to collect similar
            information directly from their website or app by integrating our
            advertising technology. Information shared by ad partners and
            affiliates or collected by SAPS.ai from the websites and apps of ad
            partners and affiliates may be combined with the other information
            you share with SAPS.ai and that SAPS.ai receives about you described
            elsewhere in our Privacy Policy.
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography
            className="Content_policy_subHeading"
            sx={{ marginBottom: "3rem" }}
          >
            g. Other Third Parties and Affiliates
          </Typography>
          <Typography className="Content_policy_Paragraph_typography2">
            We may receive information about you from third parties who are not
            our ad partners, such as others on SAPS.ai, partners who help us
            evaluate the safety and quality of content on our platform, our
            corporate affiliates, and other services you link to your SAPS.ai
            account. You may choose to connect your SAPS.ai account to accounts
            on another service, and that other service may send us information
            about your account on that service. We use the information we
            receive to provide you features like cross-posting or cross-service
            authentication, and to operate our services. For integrations that
            SAPS.ai formally supports, you may revoke this permission at any
            time from your application settings; for other integrations, please
            visit the other service you have connected to SAPS.ai.
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography
            className="Content_policy_subHeading"
            sx={{ marginBottom: "3rem" }}
          >
            h. Personalizing Based On Your Inferred Identity
          </Typography>
          <Typography className="Content_policy_Paragraph_typography2">
            When you log into SAPS.ai on a browser or device, we will associate
            that browser or device with your account for purposes such as
            authentication, security, and personalization. Subject to your
            settings, we may also associate your account with browsers or
            devices other than those you use to log into SAPS.ai (or associate
            your logged-out device or browser with other browsers or devices).
            When you provide other information to SAPS.ai, including an email
            address, we associate that information with your SAPS.ai account.
            Subject to your settings, we may also use this information in order
            to infer other information about your identity, for example by
            associating your account with hashes of email addresses that share
            common components with the email address you have provided to
            SAPS.ai. We do this to operate and personalize our services. For
            example, if you visit websites with sports content on your laptop,
            we may show you sports-related ads on SAPS.ai for Android and, if
            the email address associated with your account shares components
            with another email address, such as shared first name, last name, or
            initials, we may later match advertisements to you from advertisers
            that were trying to reach email addresses containing those
            components.
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography
            className="Content_policy_subHeading"
            sx={{ marginBottom: "3rem" }}
          >
            1. Information We Share and Disclose
          </Typography>
          <Typography className="Content_policy_Paragraph_typography2">
            As noted above, SAPS.ai is designed to broadly and instantly
            disseminate information you share publicly through our services. In
            the limited circumstances where we disclose your private personal
            data, we do so subject to your control, because it’s important for
            operating our services, or because it’s required by law.
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography
            className="Content_policy_subHeading"
            sx={{ marginBottom: "3rem" }}
          >
            a. Sharing You Control
          </Typography>
          <Typography className="Content_policy_Paragraph_typography2">
            We share or disclose your personal data with your consent or at your
            direction, such as when you authorize a third-party web client or
            application to access your account or when you direct us to share
            your feedback with a business. Similarly, to improve your
            experience, we work with third-party partners to display their video
            content on SAPS.ai. When you watch or otherwise interact with
            content from these partners, they may receive and process your
            personal data as described in their privacy policies. If you do not
            want this content to play automatically, you can adjust your auto
            play settings. If you’ve shared information like Direct Messages or
            protected Content with someone else who accesses SAPS.ai through a
            third-party service, keep in mind that the information may be shared
            with the third-party service. Subject to your settings, we also
            provide certain third parties with personal data to help us offer or
            operate our services. You can learn more about these partnerships in
            our Help Center, and you can control whether SAPS.ai shares your
            personal data in this way by using the “Allow additional information
            sharing with business partners” option in your Personalization and
            Data settings. (This setting does not control sharing described
            elsewhere in our Privacy Policy, such as when we share data with our
            service providers or through partnerships other than as described in
            our Help Center)
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography
            className="Content_policy_subHeading"
            sx={{ marginBottom: "3rem" }}
          >
            b. Service Providers
          </Typography>
          <Typography className="Content_policy_Paragraph_typography2">
            We engage service providers to perform functions and provide
            services for us in India and other countries. For example, we use a
            variety of third-party services to help operate our services, such
            as hosting our various blogs and wikis, and to help us understand
            the use of our services, such as Google Analytics. We may share your
            private personal data with such service providers subject to
            obligations consistent with this Privacy Policy and any other
            appropriate confidentiality and security measures, and on the
            condition that the third parties use your private personal data only
            on our behalf and pursuant to our instructions (service providers
            may use other non-personal data for their own benefit). We share
            your payment information with payment services providers to process
            payments; prevent, detect, and investigate fraud or other prohibited
            activities; facilitate dispute resolution such as chargebacks or
            refunds; and for other purposes associated with the acceptance of
            credit and debit cards.
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography
            className="Content_policy_subHeading"
            sx={{ marginBottom: "3rem" }}
          >
            c. Law, Harm, and the Public Interest
          </Typography>
          <Typography className="Content_policy_Paragraph_typography2">
            Notwithstanding anything to the contrary in this Privacy Policy or
            controls we may otherwise offer to you, we may preserve, use, share,
            or disclose your personal data or other safety data if we believe
            that it is reasonably necessary to comply with a law, regulation,
            legal process, or governmental request; to protect the safety of any
            person; to protect the safety or integrity of our platform,
            including to help prevent spam, abuse, or malicious actors on our
            services, or to explain why we have removed content or accounts from
            our services8; to address fraud, security, or technical issues; or
            to protect our rights or property or the rights or property of those
            who use our services. However, nothing in this Privacy Policy is
            intended to limit any legal defenses or objections that you may have
            to a third party’s, including a government’s, request to disclose
            your personal data.
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography
            className="Content_policy_subHeading"
            sx={{ marginBottom: "3rem" }}
          >
            d. Affiliates and Change of Ownership
          </Typography>
          <Typography className="Content_policy_Paragraph_typography2">
            In the event that we are involved in a bankruptcy, merger,
            acquisition, reorganization, or sale of assets, your personal data
            may be sold or transferred as part of that transaction. This Privacy
            Policy will apply to your personal data as transferred to the new
            entity. We may also disclose personal data about you to our
            corporate affiliates in order to help operate our services and our
            affiliates’ services, including the delivery of ads.
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography
            className="Content_policy_subHeading"
            sx={{ marginBottom: "3rem" }}
          >
            e. Non-Personal Information
          </Typography>
          <Typography className="Content_policy_Paragraph_typography2">
            We share or disclose non-personal data, such as aggregated
            information like the total number of times people engaged with your
            content, demographics, the number of people who clicked on a
            particular link or voted on a poll, the topics that people are
            following about in a particular location, some inferred interests,
            or reports to advertisers about how many people saw or clicked on
            their ads.
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography
            className="Content_policy_subHeading"
            sx={{ marginBottom: "3rem" }}
          >
            2. Managing Your Personal Information With Us
          </Typography>
          <Typography className="Content_policy_Paragraph_typography2">
            You control the personal data you share with us. You can access or
            rectify this data at any time. You can also deactivate your account.
            We also provide you tools to object, restrict, or withdraw consent
            where applicable for the use of data you have provided to SAPS.ai.
            And we make the data you shared through our services portable and
            provide easy ways for you to contact us. Please note, to help
            protect your privacy and maintain security, we take steps to verify
            your identity before granting you access to your personal
            information or complying with deletion, portability, or other
            related requests.
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography
            className="Content_policy_subHeading"
            sx={{ marginBottom: "3rem" }}
          >
            a. Accessing or Rectifying Your Personal Data
          </Typography>
          <Typography className="Content_policy_Paragraph_typography2">
            If you have registered an account on SAPS.ai, we provide you with
            tools and account settings to access, correct, delete, or modify the
            personal data you provided to us and associated with your account.
            You can download certain account information, including your
            content, by following the instructions here. To submit a request
            related to access, modification, or deletion of your information, or
            someone else’s information if you are their authorized agent, you
            may also contact us as specified in the How To Contact Us section of
            our Privacy Policy (Additional Information or Assistance). We may
            require you to provide additional information for verification.
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography
            className="Content_policy_subHeading"
            sx={{ marginBottom: "3rem" }}
          >
            b. Deletion
          </Typography>
          <Typography className="Content_policy_Paragraph_typography2">
            We keep Log Data for a maximum of 24 months. If you follow the
            instructions here, your account will be deactivated. When
            deactivated, your SAPS.ai account, including your display name,
            username, and public profile, will no longer be viewable on
            SAPS.ai.com, SAPS.ai for iOS, and SAPS.ai for Android. For up to 30
            days after deactivation it is still possible to restore your SAPS.ai
            account if it was accidentally or wrongfully deactivated. Keep in
            mind that search engines and other third parties may still retain
            copies of your public information, like your profile information and
            public Tweets, even after you have deleted the information from our
            services or deactivated your account. Learn more here.
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography
            className="Content_policy_subHeading"
            sx={{ marginBottom: "3rem" }}
          >
            c. Object, Restrict, or Withdraw Consent
          </Typography>
          <Typography className="Content_policy_Paragraph_typography2">
            When you are logged into your SAPS.ai account, you can manage your
            privacy settings and other account features here at any time. It may
            take a short amount of time for privacy settings to be fully
            reflected throughout our systems.
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography
            className="Content_policy_subHeading"
            sx={{ marginBottom: "3rem" }}
          >
            d. Portability
          </Typography>
          <Typography className="Content_policy_Paragraph_typography2">
            SAPS.ai provides you a means to download the information you have
            shared through our services by following the steps here. Periscope
            provides you a means to download the information you have shared
            through our services by following the steps here.
          </Typography>
        </Grid>

        <Grid xs={12}>
          <Typography
            className="Content_policy_subHeading"
            sx={{ marginBottom: "3rem" }}
          >
            e. Additional Information or Assistance
          </Typography>
          <Typography className="Content_policy_Paragraph_typography2">
            Thoughts or questions about this Privacy Policy? Please let us know
            by contacting us here or writing to us at the appropriate address
            below.
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography
            className="Content_policy_subHeading"
            sx={{ marginBottom: "3rem" }}
          >
            3. Children and Our Services
          </Typography>
          <Typography className="Content_policy_Paragraph_typography2">
            Our services are not directed to children, and you may not use our
            services if you are under the age of 18. You must also be old enough
            to consent to the processing of your personal data in your country
            (in some countries we may allow your parent or guardian to do so on
            your behalf)
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography
            className="Content_policy_subHeading"
            sx={{ marginBottom: "3rem" }}
          >
            4. Our Global Operations and Data Transfers
          </Typography>
          <Typography className="Content_policy_Paragraph_typography2">
            To bring you our services, we operate globally. Where the laws of
            your country allow you to do so, you authorize us to transfer,
            store, and use your data in India and any other country where we
            operate. In some of the countries to which we transfer personal
            data, the privacy and data protection laws and rules regarding when
            government authorities may access data may vary from those of your
            country.
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography
            className="Content_policy_subHeading"
            sx={{ marginBottom: "3rem" }}
          >
            5. Changes to This Privacy Policy
          </Typography>
          <Typography
            className="Content_policy_Paragraph_typography2"
            sx={{ marginBottom: "2rem" }}
          >
            We may revise this Privacy Policy from time to time. The most
            current version of the policy will govern our processing of your
            personal data and will always be at https://SAPS.ai.com/privacy. If
            we make a change to this policy that, in our sole discretion, is
            material, we will notify you within SAPS.ai.com, SAPS.ai for iOS, or
            SAPS.ai for Android, via a SAPS.ai owned and operated SAPS.ai
            account (for example @SAPS.aiSupport), or by sending an email to the
            email address associated with your account. By continuing to access
            or use the Services after those changes become effective, you agree
            to be bound by the revised Privacy Policy.
          </Typography>
          <Typography className="Content_policy_Paragraph_typography2">
            How You Control the Information You Share with Us
          </Typography>
          <Typography className="Content_policy_Paragraph_typography2">
            Your Privacy and safety settings let you decide:
          </Typography>
          <ul style={{ paddingLeft: "2rem" }}>
            <li>
              <Typography className="Content_policy_Paragraph_typography2">
                Whether you will be able to receive Direct Messages from anyone
                on SAPS.ai or just your followers
              </Typography>
            </li>
            <li>
              <Typography className="Content_policy_Paragraph_typography2">
                Whether others can find you based on your email or phone number
              </Typography>
            </li>
            <li>
              <Typography className="Content_policy_Paragraph_typography2">
                Whether you upload your address book to SAPS.ai for storage and
                use
              </Typography>
            </li>
            <li>
              <Typography className="Content_policy_Paragraph_typography2">
                When and where you may see sensitive content on SAPS.ai
              </Typography>
            </li>
            <li>
              <Typography className="Content_policy_Paragraph_typography2">
                Whether you want to block or mute other SAPS.ai accounts
              </Typography>
            </li>
          </ul>
        </Grid>
      </Grid>
    </>
  );
};

export default PrivacyPolicies;
