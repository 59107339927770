import React from "react";
import { Grid, Typography, Button, Tabs, Tab, Box } from "@mui/material";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";
import Insights from "../../assets/Video/Insights.mp4";
import campaignVdo from "../../assets/Video/Untitled design (8).mp4";
import billboardsVdo from "../../assets/Video/Untitled design (3).mp4";
import advertiseVdo from "../../assets/Video/Untitled design (10).mp4";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Typography>{children}</Typography>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SideTabResponsiveTab = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(value, "i am value");
  };
  const sideTabContent = [
    { title: "Campaigns", vdo: campaignVdo },
    { title: "Advertise", vdo: advertiseVdo },
    { title: "Billboards", vdo: billboardsVdo },
    { title: "Insights", vdo: Insights },
  ];

  return (
    <Grid
      container
      sx={{ width: "90%", marginInline: "auto", marginTop: "3rem" }}
    >
      <Grid
        item
        xs={12}
        sx={{
          width: "100%",
          marginBottom: "2rem",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{
            "& .css-ptiqhd-MuiSvgIcon-root": {
              fontSize: "3rem",
            },
          }}
          variant="scrollable"
          scrollButtons="auto"
          className="homePageTabSideTab"
          // allowScrollButtonsMobile
          aria-label="scrollable auto tabs example"
          centered
          TabIndicatorProps={{
            style: {
              display: "none",
            },
          }}
        >
          <Tab
            className=" GrayColorTypography fontSize20px textAlignStart marginBottom20px fontColorC4C4C4"
            // sx={{ width: "25%" }}
            label="Campaigns"
            {...a11yProps(0)}
          />
          <Tab
            className=" GrayColorTypography fontSize20px textAlignStart marginBottom20px fontColorC4C4C4"
            label="Advertise"
            {...a11yProps(1)}
          />
          <Tab
            className="SidetabActive GrayColorTypography fontSize20px textAlignStart marginBottom20px fontColorC4C4C4"
            label="Billboards"
            {...a11yProps(2)}
          />
          <Tab
            className=" GrayColorTypography textAlignStart fontSize20px marginBottom20px fontColorC4C4C4"
            // sx={{ width: "25%" }}
            label="Insights"
            {...a11yProps(3)}
          />
        </Tabs>
      </Grid>
      <Grid container sx={{ padding: "4rem 0" }}>
        <Grid item xs={12} md={12}>
          {sideTabContent.map((a, i) => (
            <>
              <TabPanel
                textAlignStart
                value={value}
                index={i}
                style={{ width: "100%" }}
              >
                <Grid container style={{ width: "100%" }}>
                  <ReactPlayer
                    url={a.vdo}
                    vimeoConfig={{ iframeParams: { fullscreen: 0 } }}
                    width={"100%"}
                    height={"100%"}
                    controls={true}
                  />
                </Grid>
              </TabPanel>
            </>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SideTabResponsiveTab;
