import * as React from "react";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import { Drawer, Grid, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import logoImage from "../../../assets/Images/Group 6357267.png";
import { Link } from "react-router-dom";
const NewDrawerTest = () => {
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const handleClick2 = () => {
    setOpen2(!open2);
  };
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen1(!open1);
  };

  return (
    <>
      <Drawer
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            backgroundColor: "#ffffff",
            width: "25rem",
          },
        }}
        open={open}
        onClose={() => {
          setOpen(false);
          setOpen1(false);
          setOpen2(false);
        }}
      >
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <Link to="/">
            <Grid container>
              <ListItemButton
                onClick={() => {
                  setOpen(false);
                }}
              >
                <img
                  src={logoImage}
                  style={{ margin: "1rem 0 1.8rem 3rem", width: "70%" }}
                />
              </ListItemButton>
            </Grid>
          </Link>
          <ListItemButton
            // onClick={() => setOpen(false)}
            onClick={handleClick}
          >
            <ListItemText
              sx={{
                color: "#808080",
                fontSize: "40rem",
                "& .css-10hburv-MuiTypography-root": {
                  fontSize: "2rem",
                  fontWeight: "600",
                  marginLeft: "1.6rem",
                },
              }}
              className={open1 ? "FontColorb935b9fa" : ""}
            >
              Solutions
            </ListItemText>
            {open1 ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open1} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link to="/Agency" style={{ textDecoration: "none" }}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => setOpen(false)}>
                  <ListItemText
                    sx={{
                      color: "#808080",
                      fontSize: "40rem",
                      "& .css-10hburv-MuiTypography-root": {
                        fontSize: "2rem",
                        fontWeight: "600",
                        marginLeft: "1.6rem",
                      },
                    }}
                  >
                    Ad-Agencies
                  </ListItemText>
                </ListItemButton>
              </Link>
            </List>
            <List component="div" disablePadding>
              <Link to="/BuisnessOwner" style={{ textDecoration: "none" }}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => setOpen(false)}>
                  <ListItemText
                    sx={{
                      color: "#808080",
                      fontSize: "40rem",
                      "& .css-10hburv-MuiTypography-root": {
                        fontSize: "2rem",
                        fontWeight: "600",
                        marginLeft: "1.6rem",
                      },
                    }}
                  >
                    Media-Owners
                  </ListItemText>
                </ListItemButton>
              </Link>
            </List>
            <List component="div" disablePadding>
              <Link to="/Brands" style={{ textDecoration: "none" }}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => setOpen(false)}>
                  <ListItemText
                    sx={{
                      color: "#808080",
                      fontSize: "40rem",
                      "& .css-10hburv-MuiTypography-root": {
                        fontSize: "2rem",
                        fontWeight: "600",
                        marginLeft: "1.6rem",
                      },
                    }}
                  >
                    Brands
                  </ListItemText>
                </ListItemButton>
              </Link>
            </List>
            <List component="div" disablePadding>
              <Link to="/NormalUser" style={{ textDecoration: "none" }}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => setOpen(false)}>
                  <ListItemText
                    sx={{
                      color: "#808080",
                      fontSize: "40rem",
                      "& .css-10hburv-MuiTypography-root": {
                        fontSize: "2rem",
                        fontWeight: "600",
                        marginLeft: "1.6rem",
                      },
                    }}
                  >
                    Public
                  </ListItemText>
                </ListItemButton>
              </Link>
            </List>
          </Collapse>

          <ListItemButton onClick={() => setOpen(false)}>
            <Link to="/Product" style={{ textDecoration: "none" }}>
              <ListItemText
                sx={{
                  color: "#808080",
                  fontSize: "40rem",
                  "& .css-10hburv-MuiTypography-root": {
                    fontSize: "2rem",
                    fontWeight: "600",
                    marginLeft: "1.6rem",
                  },
                }}
              >
                Products
              </ListItemText>
            </Link>
          </ListItemButton>
          <ListItemButton onClick={() => setOpen(false)}>
            <Link to="/HowItWorks" style={{ textDecoration: "none" }}>
              <ListItemText
                sx={{
                  color: "#808080",
                  fontSize: "40rem",
                  "& .css-10hburv-MuiTypography-root": {
                    fontSize: "2rem",
                    fontWeight: "600",
                    marginLeft: "1.6rem",
                  },
                }}
              >
                How It Works
              </ListItemText>
            </Link>
          </ListItemButton>
          <ListItemButton
            // onClick={() => setOpen(false)}
            onClick={handleClick2}
          >
            <ListItemText
              sx={{
                color: "#808080",
                fontSize: "40rem",
                "& .css-10hburv-MuiTypography-root": {
                  fontSize: "2rem",
                  fontWeight: "600",
                  marginLeft: "1.6rem",
                },
              }}
              className={open2 ? "FontColorb935b9fa" : ""}
            >
              Company
            </ListItemText>
            {open2 ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open2} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link to="/AboutUs" style={{ textDecoration: "none" }}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => setOpen(false)}>
                  <ListItemText
                    sx={{
                      color: "#808080",
                      fontSize: "40rem",
                      "& .css-10hburv-MuiTypography-root": {
                        fontSize: "2rem",
                        fontWeight: "600",
                        marginLeft: "1.6rem",
                      },
                    }}
                  >
                    About Us
                  </ListItemText>
                </ListItemButton>
              </Link>
            </List>
            <List component="div" disablePadding>
              <Link to="/contact_us" style={{ textDecoration: "none" }}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => setOpen(false)}>
                  <ListItemText
                    sx={{
                      color: "#808080",
                      fontSize: "40rem",
                      "& .css-10hburv-MuiTypography-root": {
                        fontSize: "2rem",
                        fontWeight: "600",
                        marginLeft: "1.6rem",
                      },
                    }}
                  >
                    Contact Us
                  </ListItemText>
                </ListItemButton>
              </Link>
            </List>
          </Collapse>
        </List>
      </Drawer>
      <IconButton
        sx={{ marginLeft: "auto", color: "black" }}
        onClick={() => setOpen(!open)}
      >
        <MenuIcon sx={{ height: "50px", width: "100%", height: "20px" }} />
      </IconButton>
    </>
  );
};

export default NewDrawerTest;
