import {
  Typography,
  Grid,
  Button,
  useTheme,
  useMediaQuery,
  Box,
} from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import ReactPlayer from "react-player";
import heroImage from "../../../assets/Images/howitswork.png";
import howItWorks from "../../../assets/Video/HowItWorks.mp4";
import howItWorks2 from "../../../assets/Video/Howitswork2.mp4";
import howItWorks3 from "../../../assets/Video/Howitswork3.mp4";
import howItWorks4 from "../../../assets/Video/Howitswork4.mp4";
import { NavLink } from "react-router-dom";
import BasicTabs from "./HowItsWorksTab/BasicTabs";
const HowItWorksPage = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  React.useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <Grid container className="positionRelative heroImageGrid">
        <Grid item xs={12} md={12} sx={{ height: "100%", width: "100%" }}>
          <img src={heroImage} className="heroSectionClass widh100" />
        </Grid>
        <Grid
          item
          xs={12}
          className="positionAbsolute BuisnessOwnerHeroSectionDiv"
        >
          <Grid container className="heroTextgap">
            <Grid item xs={12}>
              <Typography className="BuisnessOwnerHeadingFontSize">
                Building a National or Local Advertising campaign is just a few
                clicks away
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div>
                <Typography className=" heroSectionParaMarginBottom BuisnessOwnerParaFontSize2">
                  Our platform enables to plan and launch digital Ooh
                  advertising campaigns
                </Typography>
                <Typography className=" BuisnessOwnerParaFontSize2">
                  with click of a button sitting in comfort of your home or on
                  the beach
                </Typography>
              </div>
            </Grid>
            <Box
              sx={{
                textAlign: "center",
                width: "100%",
              }}
            >
              <NavLink to="/contact_us" style={{ textDecoration: "none" }}>
                <Button className="heroSectionButton">Request A Demo</Button>
              </NavLink>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Container maxWidth="xl">
        <Typography
          className="fontSize48px fontWeight600 Fontcolor2f3237 textAlignCentre "
          sx={{ marginTop: "4rem" }}
        >
          Planning and launching a campaign is now a
        </Typography>
        <Typography
          className="fontSize48px fontWeight600 Fontcolor2f3237 textAlignCentre"
          sx={{ marginBottom: "1rem" }}
        >
          child’s play.
        </Typography>
        <Typography className="fontSize32px fontWeight400 Fontcolor2f3237 textAlignCentre">
          From an idea to over screens, in just a few easy steps.
        </Typography>
        <BasicTabs />
        {/* <Grid
          container
          className={isMd ? "widh90 gap30px" : "widh90"}
          sx={{ marginTop: "5.6rem" }}
        >
          <Grid item xs={12} md={6}>
            <Grid
              container
              sx={{ height: "100%" }}
              className="widh80"
              direction="column"
              justifyContent="center"
              gap="2rem"
            >
              <Typography className="fontSize20px fontWeight600 FontColor8894A4">
                Step 1
              </Typography>
              <Typography className="heading1">
                select your target audience & locations{" "}
              </Typography>
              <Typography className="subHeading1">
                Each unit in our system has details on impressions, historical
                cost, previous advertisers, and demographics that we collect
                from a number of different sources, so you can know if it’s the
                unit for your campaign.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
         

            <ReactPlayer
              url={howItWorks}
              vimeoConfig={{ iframeParams: { fullscreen: 0 } }}
              width={"100%"}
              height={"100%"}
              controls={true}
              loop={true}
              config={{
                file: {
                  attributes: {
                    autoPlay: true,
                    muted: true,
                  },
                },
              }}
            />
          </Grid>
        </Grid>

        <Grid
          container
          className={isMd ? "widh90 gap30px" : "widh90"}
          sx={{ marginTop: "10rem" }}
        >
          <Grid item xs={12} md={6}>
         

            <ReactPlayer
              url={howItWorks2}
              vimeoConfig={{ iframeParams: { fullscreen: 0 } }}
              width={"100%"}
              height={"100%"}
              controls={true}
              loop={true}
              config={{
                file: {
                  attributes: {
                    autoPlay: true,
                    muted: true,
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid
              container
              sx={{ height: "100%" }}
              className="widh80"
              direction="column"
              justifyContent="center"
              gap="2rem"
            >
              <Typography className="fontSize20px fontWeight600 FontColor8894A4">
                Step 2
              </Typography>
              <Typography className="heading1 ">
                Select your Billboards
              </Typography>
              <Typography className="subHeading1">
                choose a location based on types of audiences, custom audiences,
                specific locations or points of interest
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          className={isMd ? "widh90 gap30px" : "widh90"}
          sx={{ marginTop: "10rem" }}
        >
          <Grid item xs={12} md={6}>
            <Grid
              container
              sx={{ height: "100%" }}
              className="widh80"
              direction="column"
              justifyContent="center"
              gap="2rem"
            >
              <Typography className="fontSize20px fontWeight600 FontColor8894A4">
                Step 3
              </Typography>
              <Typography className="heading1">upload content</Typography>
              <Typography className="subHeading1">
                design your own ad or choose from our library of ad templates or
                hire creative services from our CreativeHub
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
           

            <ReactPlayer
              url={howItWorks3}
              vimeoConfig={{ iframeParams: { fullscreen: 0 } }}
              width={"100%"}
              height={"100%"}
              controls={true}
              loop={true}
              config={{
                file: {
                  attributes: {
                    autoPlay: true,
                    muted: true,
                  },
                },
              }}
            />
          </Grid>
        </Grid>

        <Grid
          container
          className={isMd ? "widh90 gap30px" : "widh90"}
          sx={{ marginTop: "10rem" }}
        >
          <Grid item xs={12} md={6}>
          

            <ReactPlayer
              url={howItWorks4}
              vimeoConfig={{ iframeParams: { fullscreen: 0 } }}
              width={"100%"}
              height={"100%"}
              controls={true}
              loop={true}
              config={{
                file: {
                  attributes: {
                    autoPlay: true,
                    muted: true,
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid
              container
              sx={{ height: "100%" }}
              className="widh80"
              direction="column"
              justifyContent="center"
              gap="2rem"
            >
              <Typography className="fontSize20px fontWeight600 FontColor8894A4">
                Final
              </Typography>
              <Typography className="heading1 ">Schedule</Typography>
              <Typography className="subHeading1">
                Add one or more date and time ranges for your campaign
              </Typography>
              <div style={{ marginTop: "4rem" }}>
                <Button className="btn">Post My Add Now</Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="column" sx={{ padding: "10rem" }}>
          <Typography className="textAlignCentre fontSize48px fontWeight600 Fontcolor2f3237">
            PostMyAd is revolutionizing digital{" "}
          </Typography>
          <Typography className="textAlignCentre fontSize48px fontWeight600 Fontcolor2f3237">
            marketing and giving you more.{" "}
          </Typography>
          <Typography className="textAlignCentre fontSize48px fontWeight600 Fontcolor2f3237">
            {" "}
            Are you ready?{" "}
          </Typography>
        </Grid> */}
      </Container>
    </>
  );
};

export default HowItWorksPage;
