import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import ReactPlayer from "react-player";

import Insights from "../../assets/Video/Insights.mp4";
import campaignVdo from "../../assets/Video/Untitled design (8).mp4";
import billboardsVdo from "../../assets/Video/Untitled design (3).mp4";
import advertiseVdo from "../../assets/Video/Untitled design (10).mp4";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Typography>{children}</Typography>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function SideTabComponent() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const sideTabContent = [
    { title: "Campaigns", vdo: campaignVdo },
    { title: "Advertise", vdo: advertiseVdo },
    { title: "Billboards", vdo: billboardsVdo },
    { title: "Insights", vdo: Insights },
  ];

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
        width: "90%",
        marginInline: "auto",
        marginTop: "10rem",
      }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className="homePageTabSideTab"
        indicatorColor="none"
        sx={{
          borderRight: 1,
          borderColor: "divider",
          width: "25%",
          backgroundColor: "#F7F8FD",
          paddingTop: "1rem",
          borderRadius: "1rem",
          // alignItems: "flex-start",
          border: "1px solid #E4E6EF",
        }}
      >
        <Tab
          label="Campaigns"
          sx={{ paddingLeft: "0%" }}
          className=" GrayColorTypography fontSize20px textAlignStart marginBottom20px fontColorC4C4C4"
          {...a11yProps(0)}
        />
        <Tab
          sx={{ paddingLeft: "0%" }}
          label="Advertise"
          className=" GrayColorTypography fontSize20px textAlignStart marginBottom20px fontColorC4C4C4"
          {...a11yProps(1)}
        />
        <Tab
          sx={{ paddingLeft: "0%" }}
          label="Billboards"
          className=" GrayColorTypography fontSize20px textAlignStart marginBottom20px fontColorC4C4C4"
          {...a11yProps(2)}
        />

        <Tab
          label="Insights"
          className=" GrayColorTypography fontSize20px textAlignStart marginBottom20px fontColorC4C4C4"
          sx={{ paddingLeft: "0%" }}
          {...a11yProps(3)}
        />
      </Tabs>
      {sideTabContent.map((a, i) => (
        <TabPanel
          textAlignStart
          value={value}
          index={i}
          style={{ width: "100%" }}
        >
          <Grid container style={{ width: "100%" }}>
            <ReactPlayer
              url={a.vdo}
              vimeoConfig={{ iframeParams: { fullscreen: 0 } }}
              width={"100%"}
              height={"100%"}
              controls={true}
              config={{
                file: {
                  attributes: {
                    autoPlay: true,
                    muted: true,
                  },
                },
              }}
            />
          </Grid>
        </TabPanel>
      ))}
    </Box>
  );
}
