import React from "react";
import {
  AppBar,
  Grid,
  Toolbar,
  Typography,
  Button,
  Tabs,
  Tab,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import ReactPlayer from "react-player";
import PropTypes from "prop-types";
import buissnessOwner from "../../assets/Images/buisnessOwner";
import pub from "../../assets/Images/pub.png";
import adAgency from "../../assets/Images/ad_agency.png";
import brands from "../../assets/Images/brands.png";
import image from "../../assets/Images/1907.i039.009.P.m004.c30 1.png";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Typography>{children}</Typography>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TabComponent = () => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(value, "i am value");
  };
  const tabContent = [
    {
      title:
        "Post My Ad is ideally suited to fit seamlessly into any ad agency’s existing processes. Our services will help:",
      para1: "Deliver the personalized experiences today’s clients expect",
      para2:
        "Reach micro-targeted audiences in their preferred location and time",
      img: adAgency,
    },
    {
      title: "PostMyad empowers you by giving a unique edge over competition",
      para1:
        "Opens your windows to DOOH, to stay agile and adaptive in everchanging environment",
      para2:
        "Combines DOOH with QR codes and enables heightened customer acquisition, increased brand awareness, and customer engagement",
      img: brands,
    },
    {
      title: "When you become our Billboard Partner, you:",
      para1: "Witness increased movement into your store.",
      para2:
        "Enjoy an all-round carefree package as we take care of the installation and setup",
      img: buissnessOwner,
    },
    {
      title:
        "Postmyad Makes Digital Advertisement  Easy For Public And Accessible For Everyone",
      para1: "Get insights and plan your ads more precisely.",
      para2: "Plan your ads or play live streams on digital billboards.",
      img: pub,
    },
  ];

  return (
    <Grid
      container
      sx={{ width: "90%", margin: "auto" }}
      className={!isSm ? "marginBottom30px" : ""}
    >
      <Grid
        item
        xs={12}
        sx={{
          width: "100%",
          marginBottom: "2rem",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{
            "& .css-ptiqhd-MuiSvgIcon-root": {
              fontSize: "3rem",
            },
          }}
          className="homePageTab1"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          aria-label="scrollable auto tabs example"
        >
          <Tab
            className="tabLabelWidthTillMd mobileTypographyParagraph fontSize24px fontColorC4C4C4 fontWeight600 tabActive"
            sx={{ textAlign: "start" }}
            label="Ad-Agencies"
            {...a11yProps(0)}
          />
          <Tab
            className="tabLabelWidthTillMd mobileTypographyParagraph fontSize24px fontColorC4C4C4 fontWeight600 tabActive"
            label="Brands"
            {...a11yProps(1)}
          />
          <Tab
            className="tabLabelWidthTillMd mobileTypographyParagraph fontSize24px fontColorC4C4C4 fontWeight600 tabActive"
            label="Business owner"
            {...a11yProps(2)}
          />
          <Tab
            className="tabLabelWidthTillMd mobileTypographyParagraph fontSize24px fontColorC4C4C4 fontWeight600 tabActive"
            // sx={{ width: "25%" }}
            label="Public"
            {...a11yProps(3)}
          />
        </Tabs>
      </Grid>
      <Grid container sx={{ padding: "4rem 0" }}>
        <Grid item xs={12} md={12}>
          {tabContent.map((a, i) => (
            <>
              <TabPanel value={value} index={i}>
                <Grid container gap="2rem">
                  <Grid item xs={12} md={5.5}>
                    <Typography
                      sx={{ marginBottom: "5rem" }}
                      className="mobileTypographyParagraph fontSize20px blackFontColor fontWeight600"
                    >
                      {a.title}
                    </Typography>
                    <ul>
                      <li>
                        <Typography
                          className="tabParaTypography fontSize16px"
                          sx={{ marginBottom: "3rem" }}
                        >
                          {" "}
                          {a.para1}
                        </Typography>
                      </li>
                      <li>
                        <Typography className="tabParaTypography fontSize16px">
                          {" "}
                          {a.para2}
                        </Typography>
                      </li>
                    </ul>
                    {/* {!isSm && (
                      <div style={{ textAlign: "center" }}>
                        {" "}
                        <Button
                          sx={{
                            marginTop: "8rem",

                            margin: "center",
                          }}
                          className="tab1Button fontSize16px"
                        >
                          Learn More
                        </Button>
                      </div>
                    )} */}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    justifyContent="center"
                    alignItems="center"
                    className={!isSm ? "height400px" : ""}
                  >
                    <img
                      src={a.img}
                      width="100%"
                      height="100%"
                      className="tabImage"
                    />
                  </Grid>
                  {/* {isSm && (
                    <div
                      style={{
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      {" "}
                      <Button
                        sx={{
                          margin: "center",
                        }}
                        className="tab1Button fontSize16px"
                      >
                        Learn More
                      </Button>
                    </div>
                  )} */}
                </Grid>
              </TabPanel>
            </>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TabComponent;
