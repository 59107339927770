import * as React from "react";

import {
  AppBar,
  Grid,
  Toolbar,
  Typography,
  Button,
  Tabs,
  Tab,
  Box,
  useTheme,
  useMediaQuery,
  InputBase,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Link,
} from "@mui/material";
// import TabComponent from "../component/TabComponent/TabComponent.js";
import Test from "../../Common/test";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
// import "./HomePage.css";
import SideTabComponent from "../../TabComponent/SideTabComponent";
import SideTabResponsiveTab from "../../TabComponent/SideTabResponsiveTab";

import logoImage from "../../../assets/Images/Group 6357267.png";

import heroImage from "../../../assets/Images/hero 3.jpg";
import postMyAd from "../../../assets/Images/HOMEIMG.png";
import targetting from "../../../assets/Images/HeroCardImage1.png";
import pricing from "../../../assets/Images/HeroCardImage2.png";
import measurement from "../../../assets/Images/HeroCardImage3.png";
import mobileImage from "../../../assets/Images/Rectangle.png";
import barCode from "../../../assets/Images/Group 6356096.png";
import appStore from "../../../assets/Images/fsd.png";
import googlePlay from "../../../assets/Images/googleplay.png";
import mobileSectionImage from "../../../assets/Images/crop.png";
import exploreAllBillboards from "../../../assets/Images/Explore all billboards 1.png";
import advertisingOrder1 from "../../../assets/Images/Group 6358012.png";
import advertisingOrder2 from "../../../assets/Images/Group 6358008.png";
import advertisingOrder3 from "../../../assets/Images/Group 6358009.png";
import advertisingOrder4 from "../../../assets/Images/Group 6358013.png";
import advertisingOrder5 from "../../../assets/Images/Group 6358011.png";
import advertisingOrder6 from "../../../assets/Images/Group 6358010.png";
import advertisingOrder7 from "../../../assets/Images/Group 6358015.png";
import advertisingOrder8 from "../../../assets/Images/Group 6358016.png";
import advertisingOrder9 from "../../../assets/Images/Group 6357928.png";

import TabComponent from "../../TabComponent/TabComponent";
import NewTabComponent from "../../TabComponent/NewTabComponent";
import TabComponent2 from "../../TabComponent/TabComponent2";
import { Container } from "@mui/system";
import { NavLink } from "react-router-dom";
// import { Link } from "react-router-dom";
const adverTiseAd = [
  {
    image: "/assets/Images/1907.i039.009.P.m004.c30 1.png",
    text: "Self-serve advertising platform.",
  },
];

const HomePage = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  console.log(isMd);
  const accorDionData = [
    {
      title: "What is postmyad?",
      para1:
        "PostMyAd is a proprietary technology platform that allows individuals, businesses and agencies to run programmatic digital out of home (PDOOH) campaigns using different advertisement formats.",
    },
    {
      title: "What are my customer targeting options?",
      para1:
        "Our user data gives you more opportunities to segment users to satisfy their needs. We provide 4 different segment options: behavioral, placement, tech, and demographics, but that’s not all - these segments divide into many bullet points.",
    },
    {
      title: "Is it possible to target specific audiences only with PostMyAd?",
      para1:
        "Yes! By using PostMyAd you’ll be able to choose unique audiences and target particular groups of consumers by industries such as hospitality, real estate, pharma and many more.",
    },
    {
      title:
        "How can I create an advertising campaign if I have a shoestring budget? ",
      para1:
        "You can create interactive, rich media ads in just a few minutes by using our self-service creative generator that allows choosing from various template options. You have to simply import the elements of your brand and Voila, you’re done! Alternatively, you can visit our CreativeHub and look for professional help at nominal costs. ",
    },
    {
      title: "What is the CreativeHub?",
      para1:
        "CreativeHub is PostMyAd’s one-stop, Ad shop featuring a curated network of ad consultants, art directors, content writers, graphic designers, animators, web developers, photographers, videographers and even influencers offering an array of creative services. PostMyAd facilitates advertisers to connect with these creative experts to create high quality, custom-made graphics for their advertisements or to design their entire ad campaigns.",
    },
  ];

  const cartContent = [
    { title: "Self-serve advertising platform.", img: advertisingOrder1 },
    {
      title: "Run ad campaigns using display, video or rich media formats.",
      img: advertisingOrder2,
    },
    {
      title:
        "Reach your ideal audience and measure the impact on your performance goals.",
      img: advertisingOrder3,
    },
    { title: "Capture up to 15X more eyeballs.", img: advertisingOrder4 },
    { title: "Focus on results, conversions and ROI.", img: advertisingOrder5 },
    {
      title: "Captivate your audience with precision targeting.",
      img: advertisingOrder6,
    },
    {
      title: "Extend your messaging and measure the impact of your campaigns.",
      img: advertisingOrder7,
    },
    {
      title:
        "Pay only for real impressions by real people and count on a real return on investment.",
      img: advertisingOrder8,
    },
    {
      title: "Get transparency and more valuable exposure.",
      img: advertisingOrder9,
    },
  ];
  React.useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div>
      <Grid container className="positionRelative heroImageGrid">
        <Grid item xs={12} sx={{ height: "100%" }}>
          <img src={heroImage} className="heroSectionClass widh100" />
        </Grid>
        <Grid item xs={12} className="positionAbsolute heroSectionDiv">
          <Grid container className="heroTextgap">
            <Grid item xs={12}>
              <Typography className="WhiteColorTypography heroSectionHeading1">
                Are You Ready for The next Level Of billboard advertising ?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div>
                <Typography className="WhiteColorTypography  heroSectionParaDiv heroSectionParaMarginBottom">
                  Introducing DO IT YOURSELF (DIY) Programmatic Digital
                </Typography>
                <Typography className="WhiteColorTypography heroSectionParaDiv">
                  Out of Home (PDOOH) Advertising
                </Typography>
              </div>
            </Grid>
            <Box
              sx={{
                textAlign: "center",
                width: "100%",
              }}
            >
              <NavLink to="/contact_us" style={{ textDecoration: "none" }}>
                <Button className="heroSectionButton">Get in touch</Button>
              </NavLink>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Container maxWidth="xl">
        <Typography
          className="vilotColorHeading"
          sx={{ marginTop: "4rem", marginBottom: "2rem" }}
        >
          About
        </Typography>
        <Typography
          className="heading2 textAlignCentre"
          sx={{ marginTop: "2rem", marginBottom: "2rem" }}
        >
          The Platform
        </Typography>
        <Grid
          className="widh90 marginBottom100px"
          container
          justifyContent="center"
          gap="4rem"
        >
          <Grid xs={12}>
            <Typography
              className="DarGrayColorTypography fontSize18px textAlignCentre"
              sx={{ marginBottom: ".2rem" }}
            >
              PostMyAd is world’s first DIY app-based platform for programmatic
              digital out of
            </Typography>
            <Typography className="DarGrayColorTypography fontSize18px textAlignCentre">
              home advertising (PDOOH), enabling self-management of ads and ad
              campaigns from anywhere.
            </Typography>
          </Grid>
          <Grid item xs={10} sx={{ marginBottom: "4rem" }}>
            <img src={postMyAd} className="heroSectionClass " />
          </Grid>
        </Grid>
        <Typography
          className="vilotColorHeading "
          sx={{ marginTop: "4rem", marginBottom: "3rem" }}
        >
          This Platform Is Made For
        </Typography>
        <NewTabComponent />
        <Grid
          container
          sx={{ padding: "4rem 0rem" }}
          className="widh90  cardGapcontainer"
          justifyContent="space-between"
        >
          <Grid
            className="card mediaCardClass"
            item
            xs={12}
            sm={3.8}
            sx={{
              padding: "4rem 3rem",
            }}
          >
            <Grid
              container
              className="cardGap"
              justifyContent="center"
              alignItems="center"
              direction="column"
              // gap="2rem"
            >
              <div style={{ textAlign: "center" }}>
                <img
                  src={measurement}
                  style={{ width: "40%" }}
                  className="cardImage1"
                />
              </div>
              <Typography
                className={
                  isSm
                    ? "cardTypographyHeading fontSize20px fontColorD723cd"
                    : "cardTypographyHeading fontSize20px marginBottom20px fontColorD723cd"
                }
              >
                Target
              </Typography>
              <Typography
                className={
                  isSm
                    ? "cardTypographyParagraph widh90"
                    : "cardTypographyParagraph"
                }
                sx={{ marginTop: "1rem" }}
              >
                Use our Value-added services to identify your future customers.
                (hyper advertising)
              </Typography>
            </Grid>
          </Grid>
          <Grid
            className="card mediaCardClass"
            item
            xs={12}
            sm={3.8}
            sx={{ padding: "4rem 3rem" }}
          >
            <Grid
              container
              className="cardGap"
              justifyContent="center"
              alignItems="center"
              direction="column"
              // gap="2rem"
            >
              <div style={{ textAlign: "center" }}>
                <img
                  src={pricing}
                  style={{ width: "40%" }}
                  className="cardImage1"
                />
              </div>
              <Typography
                className={
                  isSm
                    ? "cardTypographyHeading fontSize20px fontColorD723cd"
                    : "cardTypographyHeading fontSize20px marginBottom20px fontColorD723cd"
                }
              >
                Reach{" "}
              </Typography>
              <Typography
                className={
                  isSm
                    ? "cardTypographyParagraph widh90"
                    : "cardTypographyParagraph"
                }
                sx={{ marginTop: "1rem" }}
              >
                Our Wide Network Helps You Reach Hard To Reach Audiences And
                geographies.
              </Typography>
            </Grid>
          </Grid>
          <Grid
            className="card mediaCardClass"
            item
            xs={12}
            sm={3.8}
            sx={{ padding: "4rem 3rem" }}
          >
            <Grid
              container
              className="cardGap"
              justifyContent="center"
              alignItems="center"
              direction="column"
              // gap="2rem"
            >
              <div style={{ textAlign: "center" }}>
                <img
                  src={targetting}
                  style={{ width: "40%" }}
                  className="cardImage1"
                />
              </div>
              <Typography
                className={
                  isSm
                    ? "cardTypographyHeading fontSize20px fontColorD723cd"
                    : "cardTypographyHeading fontSize20px marginBottom20px fontColorD723cd"
                }
              >
                Measure{" "}
              </Typography>
              <Typography
                className={
                  isSm
                    ? "cardTypographyParagraph widh90"
                    : "cardTypographyParagraph"
                }
                sx={{ marginTop: "1rem" }}
              >
                Track real-time customer engagements and conversion events with
                a dashboard just like your digital marketing channels.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{ width: "100%", margin: "10rem 0" }}
          className="widh90"
        >
          <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
            <div style={{ textAlign: "center" }}>
              <img
                src={mobileImage}
                className="mobileSectionImage"
                style={{ width: "80%" }}
              />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            order={{ xs: 1, md: 2 }}
            sx={{
              padding: "2rem 0 3rem 2rem",
            }}
          >
            <Typography className="mobileTypographyParagraph textAlignCentreFromMd ">
              Ready to Up your Advertising Game?
            </Typography>
            <Typography className="bigDarkHeadingTypography textAlignCentreFromMd textTransform">
              Download Our App
            </Typography>
            <Typography className="smallParagraphTypography textAlignCentreFromMd fontSize16px">
              Get up-close-and-personal with potential customers. Go live in
              minutes.
            </Typography>
            <Typography className="smallParagraphTypography textAlignCentreFromMd fontSize16px">
              Amplify your brand’s reach in seconds.
            </Typography>
            {!isMd && (
              <div
                className="mobileSectionTextDiv marginInlineAutoFromMd fontSize16px"
                style={{
                  display: "flex",
                  width: "100%",

                  gap: "4rem",
                  marginTop: "10rem",
                }}
              >
                <Link
                //  href="#"
                >
                  <img style={{ width: "100%" }} src={appStore} />
                </Link>
                <Link
                  href="https://play.google.com/store/apps/details?id=com.postmyadd"
                  target="_blank"
                >
                  <img style={{ width: "100%" }} src={googlePlay} />
                </Link>
              </div>
            )}
            {isMd && (
              <div
                className="mobileSectionTextDiv marginInlineAutoFromMd fontSize16px"
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  gap: "4rem",
                  marginTop: "10rem",
                }}
              >
                <Link
                //  href="#"
                >
                  <img style={{ width: "100%" }} src={appStore} />
                </Link>
                <Link
                  href="https://play.google.com/store/apps/details?id=com.postmyadd"
                  target="_blank"
                >
                  <img style={{ width: "100%" }} src={googlePlay} />
                </Link>
              </div>
            )}
          </Grid>
        </Grid>
        <Typography className="GrayColorTypography fontColorD723cd fontSize18px  marginTop20px">
          What to Expect
        </Typography>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Typography
              className="GrayColorTypography fontSize18px lineHeight120per textAlignCentre lineHeight30px"
              sx={{ margin: "2rem 0", letterSpacing: ".05px" }}
            >
              Wide network of billboards to choose from with detailed profiles
              of each to help you target the right audience, venue and time and
              much more.
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" sx={{ marginBottom: "10rem" }}>
          <Grid item xs={10} sx={{ textAlign: "center" }}>
            <img style={{ width: "85%" }} src={exploreAllBillboards} />
          </Grid>
        </Grid>
        <TabComponent2 />
        <Grid
          container
          className="widh90"
          gap="1rem"
          sx={{ marginTop: "10rem" }}
        >
          <Grid item xs={12}>
            {" "}
            <Typography className="GrayColorTypography fontColorD723cd width90 fontSize18px">
              Quick And Easy To Use
            </Typography>
          </Grid>
          <Grid xs={12}>
            {" "}
            <Typography className="GrayColorTypography fontSize18px">
              Planning and launching a campaign is now a child’s play.
            </Typography>
          </Grid>
        </Grid>
        {!isMd ? <SideTabComponent /> : <SideTabResponsiveTab />}
        <Box className="marginTop100px">
          <Typography
            className="GrayColorTypography fontColorD723cd fontSize24px"
            sx={{ padding: "5rem" }}
          >
            Why Advertise on PostMyAd?
          </Typography>
        </Box>
        <Grid
          container
          gap="2rem"
          className="widh90"
          // sx={{ padding: "0 0 4rem 0" }}
          justifyContent="center"
        >
          {cartContent.map((a) => (
            <>
              <Grid
                className="adverTiseCards"
                item
                xs={12}
                sm={5.5}
                lg={3.8}
                sx={{ padding: "5rem 2rem", height: "35rem" }}
              >
                <Grid
                  container
                  gap="2rem"
                  alignItems="center"
                  direction="column"
                >
                  <div
                    style={{
                      textAlign: "center",
                      width: "40%",
                      margin: "auto",
                    }}
                    className="flexBoxClass"
                  >
                    <img
                      src={a.img}
                      className="adverTiseCardImageWidth"
                      style={{
                        width: "100%",
                        height: "auto",
                      }}
                    />
                  </div>
                  <Typography
                    className="adverTiseCardTypography "
                    sx={{ textAlign: "center" }}
                  >
                    {a.title}
                  </Typography>
                </Grid>
              </Grid>
            </>
          ))}
        </Grid>
      </Container>
      <div
        className="marginBottom100px marginTop100px"
        style={{
          background: "#F6F6F6",
          padding: " 5rem 0rem",
        }}
      >
        <Grid container>
          <Grid item xs={12} sx={{ marginBottom: "5rem" }}>
            <Typography
              className={
                isSm
                  ? "accordionHeadingTypography fontSize32px"
                  : "accordionHeadingTypography fontSize32px"
              }
            >
              Frequently Asked Questions
            </Typography>
            <Typography className="adverTiseCardTypography widh90 fontSize16px">
              Reach out and we’ll answer the questions you have on your mind.
              We’ll try to respond within the next working day.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            sx={{ marginInline: "auto" }}
            className={isSm ? "padding20pxLR" : ""}
          >
            {accorDionData.map((a) => (
              <Accordion className="accordionClass">
                <AccordionSummary
                  expandIcon={<AddIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="accordionTitleTypography fontSize18px">
                    {" "}
                    {a.title}{" "}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="cardTypographyParagraph textAlignStart">
                    {a.para1}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
        </Grid>
      </div>

      <Grid container className={isSm ? "padding20pxLR" : ""}>
        <Grid
          item
          xs={12}
          sm={8}
          sx={{ marginInline: "auto", marginBottom: "3rem" }}
        >
          <Typography className="formHeading">
            Download our Product Brochure by filling in the form below:
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8} sx={{ marginInline: "auto" }}>
          <Grid container gap="1.6rem">
            <InputBase
              sx={{
                border: " 1px solid rgba(0, 0, 0, 0.2)",
                padding: "1rem  1rem",
                fontSize: "2rem",
                borderRadius: "4px",
              }}
              fullWidth
              placeholder="*First Name "
            />
            <InputBase
              sx={{
                border: " 1px solid rgba(0, 0, 0, 0.2)",
                padding: "1rem  1rem",
                fontSize: "2rem",
                borderRadius: "4px",
              }}
              fullWidth
              placeholder="*Last Name  "
            />
            <InputBase
              sx={{
                border: " 1px solid rgba(0, 0, 0, 0.2)",
                padding: "1rem  1rem",
                fontSize: "2rem",
                borderRadius: "4px",
              }}
              fullWidth
              placeholder="*Business Email "
            />
            <InputBase
              sx={{
                border: " 1px solid rgba(0, 0, 0, 0.2)",
                padding: "1rem  1rem",
                fontSize: "2rem",
                borderRadius: "4px",
              }}
              fullWidth
              placeholder="*Country you are based in  "
            />
            <InputBase
              sx={{
                border: " 1px solid rgba(0, 0, 0, 0.2)",
                padding: "1rem  1rem",
                fontSize: "2rem",
                borderRadius: "4px",
              }}
              fullWidth
              placeholder="*Company Name"
            />
            <InputBase
              sx={{
                border: " 1px solid rgba(0, 0, 0, 0.2)",
                padding: "1rem  1rem",
                fontSize: "2rem",
                borderRadius: "4px",
              }}
              fullWidth
              placeholder="*Company Type"
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          sx={{ marginInline: "auto", marginTop: "1rem" }}
        >
          <Typography className="formParagraph">
            PostMyAd process your personal data in accordance with our corporate
            data privacy policy.by ticking the boxes below,you agree here to.you
            can opt out at any time.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          sx={{ marginInline: "auto", marginTop: "3rem" }}
        >
          <Box display="flex" sx={{ alignItems: "center", marginLeft: "10px" }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{
                      transform: "scale(2)",
                    }}
                  />
                }
              />
            </FormGroup>
            <Typography className="formCheckBoxParagraph">
              I agree to PostMyAd Corporate Data Privacy Policy.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} sx={{ marginInline: "auto" }}>
          <Box display="flex" sx={{ alignItems: "center", marginLeft: "10px" }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{
                      transform: "scale(2)",
                    }}
                  />
                }
              />
            </FormGroup>
            <Typography className="formCheckBoxParagraph">
              I want to receive marketing emails about PostMyAd products and
              services.
            </Typography>
          </Box>
        </Grid>
        <div
          style={{
            textAlign: "center",
            width: "100%",
            marginTop: "3rem",
          }}
        >
          <Button className="submit_btn">Submit</Button>
        </div>
      </Grid>

      <Test />
    </div>
  );
};

export default HomePage;
