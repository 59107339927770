import React from "react";

import AgencyHeroImage from "../../../assets/Images/GroupAgency.png";
import {
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  Box,
  Button,
} from "@mui/material";
import Test from "../../Common/test";
import AgencyCardImage from "../../../assets/Images/SC1.png";
import Card from "../../Common/Card/Card";
import cardImage1 from "../../../assets/Images/cardImage1.png";
import cardImage2 from "../../../assets/Images/cardImage2.png";
import cardImage3 from "../../../assets/Images/cardImage3.png";
import cardImage4 from "../../../assets/Images/cardImage4.png";
import cardImage5 from "../../../assets/Images/cardImage5.png";
import cardImage6 from "../../../assets/Images/cardImage6.png";
import AgencyTab from "./AgencyTab1";
import { Container } from "@mui/system";
import { NavLink } from "react-router-dom";
const Agencies = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const cardContent = [
    {
      text: "Deliver the personalized experiences today’s clients expect",
      img: cardImage1,
    },
    {
      text: "Reach micro-targeted audiences in their preferred location and time",
      img: cardImage2,
    },
    {
      text: "Generate more ROI on campaigns for self and client",
      img: cardImage3,
    },

    {
      text: "Predict effective outcomes, eliminate guesswork, and maximize campaign performance",
      img: cardImage4,
    },
    {
      text: "Accurately predict what works, what doesn’t, what’s next, and why",
      img: cardImage5,
    },
    {
      text: "Shorten the sales cycle and augment revenue growth",
      img: cardImage6,
    },
  ];
  const text = "When you become our Agency Partner, you are able to:";
  React.useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div>
      <Grid container className="positionRelative heroImageGrid">
        <Grid item xs={12} sx={{ height: "100%" }}>
          <img src={AgencyHeroImage} className="heroSectionClass widh100" />
        </Grid>
        <Grid item xs={12} className="positionAbsolute AgencyHeroSectionDiv">
          <Grid container className="heroTextgap">
            <Grid item xs={12}>
              <Typography className="newHeadingFontSize">
                Unlock New Revenue Streams
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div>
                <Typography className=" heroSectionParaMarginBottom newParaFontSize2">
                  Deliver more value to your clients by building integrated
                  campaigns that
                </Typography>
                <Typography className=" newParaFontSize2">
                  include PDOOH media and Other Value added Services.
                </Typography>
              </div>
            </Grid>
            <Box
              sx={{
                textAlign: "center",
                width: "100%",
              }}
            >
              <NavLink to="/contact_us" style={{ textDecoration: "none" }}>
                <Button className="heroSectionButton">Get in touch</Button>
              </NavLink>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Container maxWidth="xl">
        <Typography
          className="vilotColorHeading"
          sx={{ marginTop: "4rem", marginBottom: "2rem" }}
        >
          Our Solutions
        </Typography>
        <Typography
          className="heading2 textAlignCentre"
          sx={{ marginTop: "2rem", marginBottom: "2rem" }}
        >
          Opening doors to futuristic advertising
        </Typography>
        <Grid
          container
          className={
            isMd ? "widh90 marginTop50px gap20px" : "widh90 marginTop100px"
          }
          justifyContent="space-between"
        >
          <Grid item xs={12} md={5.5}>
            <Grid
              container
              gap="3rem"
              direction="column"
              justifyContent="center"
              sx={{ height: "100%" }}
            >
              <Typography className="heading1">
                {" "}
                Easily Plan Your ad for your clients
              </Typography>
              <Typography className="subHeading1">
                whether you want to plan and execute your out of home
                advertisment on digital screen for your valuable clients, you
                can plan in minutes with postmyad.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={5.5} sx={{ border: "1px solid red" }}>
            <img
              style={{ border: "1px solid black" }}
              src={AgencyCardImage}
              alt="agencyCardImage"
              className="widh100 "
            />
          </Grid>
        </Grid>
        <Grid
          container
          className={
            isMd ? "widh90 marginTop50px gap20px" : "widh90 marginTop100px"
          }
          justifyContent="space-between"
        >
          <Grid item xs={12} md={5.5} order={{ xs: 2, md: 1 }}>
            <img
              src={AgencyCardImage}
              alt="agencyCardImage"
              className="widh100 "
            />
          </Grid>
          <Grid item xs={12} md={5.5} order={{ xs: 1, md: 1 }}>
            <Grid
              container
              gap="3rem"
              direction="column"
              justifyContent="center"
              sx={{ height: "100%" }}
            >
              <Typography className="heading1">
                {" "}
                Analysis campaign success
              </Typography>
              <Typography className="subHeading1">
                Analyze Your campaign Success and understand how their ooh
                campaign are performing against the success metrics that matter
                to your clients . Oh, and, track all your clients’ campaigns in
                a modern dashboard, just like all their other channels.
              </Typography>
              {/* <Typography className="subHeading1">
                Oh, and, track all your clients’ campaigns in a modern
                dashboard, just like all their other channels.
              </Typography> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          className={
            isMd ? "widh90 marginTop50px gap20px" : "widh90 marginTop100px"
          }
          justifyContent="space-between"
        >
          <Grid item xs={12} md={5.5}>
            <Grid
              container
              gap="3rem"
              direction="column"
              justifyContent="center"
              sx={{ height: "100%" }}
            >
              <Typography className="heading1">
                {" "}
                add new members in just few seconds
              </Typography>
              <Typography className="subHeading1">
                Add New clients and Members in a few seconds on postmyad
                platform with easy Onboarding process .
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={5.5}>
            <img
              src={AgencyCardImage}
              alt="agencyCardImage"
              className="widh100 "
            />
          </Grid>
        </Grid>
        <AgencyTab />
        <Card cardContent={cardContent} text={text} />
      </Container>
      <Test />
    </div>
  );
};

export default Agencies;
