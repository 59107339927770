import { Typography, Grid, Button } from "@mui/material";
import productPageHeroImage from "../../../assets/Images/productPageHeroImage.png";
import heroDeviceImage from "../../../assets/Images/hero_xkltx_fb 1.png";
import { Container } from "@mui/system";
import Test from "../../Common/test";
import React from "react";
import { NavLink } from "react-router-dom";

const ProductPage = () => {
  // React.useEffect(() => {
  //   window.scroll(0, 0);
  // }, []);
  return (
    <>
      <Container maxWidth="xl">
        <div className="widh90">
          <Grid container sx={{ marginTop: "72px", padding: "4rem 0" }}>
            <Grid
              xs={12}
              // sx={{ border: "1px solid red" }}
            >
              <Grid container>
                <Grid
                  xs={12}
                  md={6}
                  // sx={{ border: "1px solid black" }}
                >
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    sx={{ height: "100%" }}
                    gap="2rem"
                  >
                    <div>
                      <Typography className="heading2">
                        Convert your glass facade into a Digital billboard
                      </Typography>
                      {/* <Typography className="heading2">billboard</Typography> */}
                    </div>
                    <Typography className="subHeading1">
                      With our proprietary technology, you can now convert your
                      glass facade into an attractive SMART billboard
                    </Typography>
                    <div style={{ marginTop: "4rem" }}>
                      <NavLink
                        to="/contact_us"
                        style={{ textDecoration: "none" }}
                      >
                        <Button className="btn">Get in touch </Button>
                      </NavLink>
                    </div>
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  md={6}
                  sx={
                    {
                      //  border: "1px solid green"
                    }
                  }
                >
                  <Grid
                    container
                    sx={{ width: "100%" }}
                    justifyContent="center"
                  >
                    <img
                      src={productPageHeroImage}
                      style={{
                        width: "80%",
                        //   border: "1px solid pink"
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            className="marginTop100px"
            direction="column"
            gap="1rem"
            sx={{ padding: "2rem 4rem" }}
          >
            <Typography
              className="textAlignCentre heading2"
              //   sx={{ marginBottom: "1rem" }}
            >
              Turn-key Hardware Options
            </Typography>
            <Typography className="textAlignCentre subHeading1 fontSize16px">
              postmyad works with leading kiosk and hardware providers to
              provide a turn-key solution. While we only recommend high-quality
              hardware providers, we are supplier agnostic, meaning our software
              works with a variety of hardware types, regardless of orientation,
              screen size, or resolution.
            </Typography>
          </Grid>
          <Grid
            container
            className="marginTop100px boxShadow1"
            sx={{ padding: "4rem 6rem" }}
            gap="4rem"
          >
            <Grid item xs={12}>
              <Typography className="textAlignCentre heading1">
                IoT Media Player
              </Typography>
              <Typography className="textAlignCentre subHeading1 fontSize16px">
                Pushing the edge of DOOH and IOT.
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: "5rem" }}>
              <Grid container sx={{ width: "100%" }} justifyContent="center">
                <img src={heroDeviceImage} style={{ width: "60%" }} />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography
                className="subHeading1 fontSize16px"
                sx={{ marginBottom: "2rem" }}
              >
                The postMyAd XK-LTX is rich with robust features like a Micro SD
                card reader and USB 3.0 / 2.0 ports to help make an extra small
                device extra smart for all your IOT needs. WiFi/BT and GbE LAN
                for connectivity, 64GB eMMC storage, and 4GB LPDDR4 memory for
                performance, and powered by the Intel® Apollo Lake Celeron N3350
                Processor, the postMy Ad XK-LTX makes reliable 4K playback both
                easy and affordable.
              </Typography>
              <Typography className="subHeading1 fontSize16px">
                Arrow’s Advanced Engineering Solution center takes product
                development very seriously. postMyAd devices endure a rigorous
                protocol of real-world testing and optimizations while
                withstanding the pressures of grueling environments. The
                expectation is uncompromised performance, and the XK-LTX is no
                exception. The postMyAd XK-LTX is a cost-effective means of
                delivering enterprise-class performance.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container rowGap="1rem">
                <Grid item xs={12}>
                  <Typography className="heading1 fontSize24px">
                    Specifications
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Grid item xs={12}>
                    <Typography
                      className="subHeading1 fontSize15px"
                      sx={{ width: "90%", marginBottom: ".8rem" }}
                    >
                      Broadcom BCM2711, Quad core Cortex-A72 (ARM v8) 64-bit SoC
                      @ 1.5GHz
                    </Typography>
                    <Typography
                      className="subHeading1 fontSize15px"
                      sx={{ width: "90%", marginBottom: ".8rem" }}
                    >
                      8GB LPDDR4-3200 SDRAM
                    </Typography>
                    <Typography
                      className="subHeading1 fontSize15px"
                      sx={{ width: "90%", marginBottom: ".8rem" }}
                    >
                      2.4 GHz and 5.0 GHz IEEE 802.11ac wireless, Bluetooth 5.0,
                      BLE Gigabit Ethernet
                    </Typography>
                    <Typography
                      className="subHeading1 fontSize15px"
                      sx={{ width: "90%", marginBottom: ".8rem" }}
                    >
                      2 USB 3.0 ports; 2 USB 2.0 ports.
                    </Typography>
                    <Typography
                      className="subHeading1 fontSize15px"
                      sx={{ width: "90%", marginBottom: ".8rem" }}
                    >
                      2 × micro-HDMI ports (up to 4kp60 supported)
                    </Typography>
                    <Typography
                      className="subHeading1 fontSize15px"
                      sx={{ width: "90%", marginBottom: ".8rem" }}
                    >
                      H.265 (4kp60 decode), H264 (1080p60 decode, 1080p30
                      encode)
                    </Typography>
                    <Typography
                      className="subHeading1 fontSize15px"
                      sx={{ width: "90%", marginBottom: ".8rem" }}
                    >
                      Operating temperature: 0 – 50 degrees C ambient
                    </Typography>
                    <Typography
                      className="subHeading1 fontSize15px"
                      sx={{ width: "90%", marginBottom: ".8rem" }}
                    >
                      5V DC via USB-C connector (minimum 3A*)
                    </Typography>
                    <Typography
                      className="subHeading1 fontSize15px"
                      sx={{ width: "90%", marginBottom: ".8rem" }}
                    >
                      Micro-SD card slot for loading operating system and data
                      storage
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid item xs={12}>
                    <Typography
                      className="subHeading1 fontSize16px blackFontColor fontWeight600 fontSize18px"
                      sx={{
                        width: "90%",
                        marginBottom: ".8rem",
                      }}
                    >
                      controlling/monitoring hat
                    </Typography>
                    <Typography
                      className="subHeading1 fontSize16px"
                      sx={{ width: "90%", marginBottom: ".8rem" }}
                    >
                      Supports 802.11 b/g/n Wi-Fi connectivity with speeds up to
                      150 Mbps
                    </Typography>
                    <Typography
                      className="subHeading1 fontSize15px"
                      sx={{ width: "90%", marginBottom: ".8rem" }}
                    >
                      Dual-Core 32-bit LX6 Microprocessor with clock frequency
                      up to 240 MHz
                    </Typography>
                    <Typography
                      className="subHeading1 fontSize15px"
                      sx={{ width: "90%", marginBottom: ".8rem" }}
                    >
                      DC Fan
                    </Typography>
                    <Typography
                      className="subHeading1 fontSize15px"
                      sx={{ width: "90%", marginBottom: ".8rem" }}
                    >
                      DHT22 Temperature and Humidity Sensor
                    </Typography>
                  </Grid>
                </Grid>
                {/* <Grid xs={12}>
                  <Grid container justifyContent="space-between">
                    <Typography className="subHeading1 fontSize16px">
                      {" "}
                      4 GB LPDDR4 Memory{" "}
                    </Typography>
                    <Typography className="subHeading1 fontSize16px">
                      {" "}
                      4K Media Player
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs={12}>
                  <Grid container justifyContent="space-between">
                    <Typography className="subHeading1 fontSize16px">
                      {" "}
                      Intel Celeron N3350
                    </Typography>
                    <Typography className="subHeading1 fontSize16px">
                      USB 3.0 / USB 2.0
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs={12}>
                  <Grid container justifyContent="space-between">
                    <Typography className="subHeading1 fontSize16px">
                      802.11ac Wi-Fi
                    </Typography>
                    <Typography className="subHeading1 fontSize16px">
                      Applications
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs={12}>
                  <Grid container justifyContent="space-between">
                    <Typography className="subHeading1 fontSize16px">
                      {" "}
                      4 GB LPDDR4 Memory{" "}
                    </Typography>
                    <Typography className="subHeading1 fontSize16px">
                      {" "}
                      4K Media Player
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs={12}>
                  <Grid container justifyContent="space-between">
                    <Typography className="subHeading1 fontSize16px">
                      Ability to play out multiple photos, videos, graphics, and
                      slideshows{" "}
                    </Typography>
                    <Typography className="subHeading1 fontSize16px">
                      {" "}
                      {""}
                    </Typography>
                  </Grid>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            className="marginTop100px"
            // sx={{ border: "1px solid red" }}
          >
            <Grid item xs={12} sm={6}>
              <img
                src={heroDeviceImage}
                style={{
                  width: "100%",
                  //   border: "1px solid black",
                  height: "100%",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ paddingLeft: "8rem" }}>
              <Typography className="heading2">
                {" "}
                Step into the future of adtech space.
              </Typography>
              <div style={{ marginTop: "4rem" }}>
                <Button className="btn">Get in touch</Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
      <Test />
    </>
  );
};

export default ProductPage;
