import React from "react";
import {
  Typography,
  Grid,
  Breadcrumbs,
  Tabs,
  Tab,
  DialogContent,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { createMuiTheme } from "@mui/material";
import PropTypes from "prop-types";
import buissnessOwner from "../../assets/Images/buisnessOwner";
import pub from "../../assets/Images/pub.png";
import adAgency from "../../assets/Images/ad_agency.png";
import brands from "../../assets/Images/brands.png";
import { Box } from "@mui/system";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children} </div>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const NewTabComponent = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const theme = createMuiTheme({
    breakpoints: {
      values: {
        xs: 0,
        tiny: 300,
        sm: 600,
        md: 900,
        lg: 1280,
        xl: 1920,
      },
    },
  });

  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  console.log(isMd);
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  console.log(isMd);

  const tabContent = [
    {
      title:
        "Post My Ad is ideally suited to fit seamlessly into any ad agency’s existing processes. Our services will help:",
      para1: "Deliver the personalized experiences today’s clients expect",
      para2:
        "Reach micro-targeted audiences in their preferred location and time",
      img: adAgency,
    },
    {
      title: "PostMyad empowers you by giving a unique edge over competition",
      para1:
        "Opens your windows to DOOH, to stay agile and adaptive in everchanging environment",
      para2:
        "Combines DOOH with QR codes and enables heightened customer acquisition, increased brand awareness, and customer engagement",
      img: brands,
    },
    {
      title: "When you become our Billboard Partner, you:",
      para1: "Witness increased movement into your store.",
      para2:
        "Enjoy an all-round carefree package as we take care of the installation and setup",
      img: buissnessOwner,
    },
    {
      title:
        "Postmyad Makes Digital Advertisement  Easy For Public And Accessible For Everyone",
      para1: "Get insights and plan your ads more precisely.",
      para2: "Plan your ads or play live streams on digital billboards.",
      img: pub,
    },
  ];
  return (
    <div className="marginBottom50px">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isSm ? (
          <Tabs
            value={value}
            onChange={handleChange}
            className="TabStyleAddCampaign"
            sx={{
              marginBottom: "5rem",
              width: "100%",
              "& .css-ptiqhd-MuiSvgIcon-root": {
                fontSize: "3rem",
              },
            }}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Ad-Agencies" className="TabChangesCampaign" />
            <Tab label="Brands" className="TabChangesCampaign" />
            <Tab label=" Business owner" className="TabChangesCampaign" />
            <Tab label="Public" className="TabChangesCampaign" />
          </Tabs>
        ) : (
          <Tabs
            value={value}
            onChange={handleChange}
            className="TabStyleAddCampaign"
            sx={{
              marginBottom: "5rem",
              width: "100%",
              "& .css-ptiqhd-MuiSvgIcon-root": {
                fontSize: "3rem",
              },
            }}
            centered={true}
          >
            <Tab label="Ad-Agencies" className="TabChangesCampaign" />
            <Tab label="Brands" className="TabChangesCampaign" />
            <Tab label=" Business owner" className="TabChangesCampaign" />
            <Tab label="Public" className="TabChangesCampaign" />
          </Tabs>
        )}

        <Grid item xs={12} md={12} className="widh90">
          {tabContent.map((a, i) => (
            <>
              <TabPanel value={value} index={i}>
                <Grid container gap="2rem">
                  <Grid item xs={12} md={5.5}>
                    <Typography
                      sx={{ marginBottom: "5rem" }}
                      className="mobileTypographyParagraph fontSize20px blackFontColor fontWeight600"
                    >
                      {a.title}
                    </Typography>
                    <ul style={{ paddingLeft: "10px" }}>
                      <li>
                        <Typography
                          className="tabParaTypography fontSize16px"
                          sx={{ marginBottom: "3rem" }}
                        >
                          {" "}
                          {a.para1}
                        </Typography>
                      </li>
                      <li>
                        <Typography className="tabParaTypography fontSize16px">
                          {" "}
                          {a.para2}
                        </Typography>
                      </li>
                    </ul>
                    {/* {!isSm && (
                      <div style={{ textAlign: "center" }}>
                        {" "}
                        <Button
                          sx={{
                            marginTop: "8rem",

                            margin: "center",
                          }}
                          className="tab1Button fontSize16px"
                        >
                          Learn More
                        </Button>
                      </div>
                    )} */}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    justifyContent="center"
                    alignItems="center"
                    className={!isSm ? "height400px" : ""}
                  >
                    <img
                      src={a.img}
                      width="100%"
                      height="100%"
                      className="tabImage"
                    />
                  </Grid>
                  {/* {isSm && (
                    <div
                      style={{
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      {" "}
                      <Button
                        sx={{
                          margin: "center",
                        }}
                        className="tab1Button fontSize16px"
                      >
                        Learn More
                      </Button>
                    </div>
                  )} */}
                </Grid>
              </TabPanel>
            </>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default NewTabComponent;
