import "./App.css";
import "./component/HomePage.css";
import "./component/Pages/Agencies/Agencies.css";
import "./component/Pages/Brands/Brands.css";
import "./component/Pages/BuisnessOwner/BuisnessOwner.css";
import "./component/Common/Header/Header.css";
import HomePage from "./component/Pages/HomePage/HomePage";
import "./component/Pages/HowItWorks/HowItsWorksTab/HowItWorkTab.css";
import Header from "./component/Common/Header/Header";
import Footer from "./component/Common/Footer/Footer";
import ContentPolicy from "./component/SapPolicies/ContentPolicy/ContentPolicy";
import PrivacyPolicies from "./component/SapPolicies/PrivacyPolicies/PrivacyPolicies";
import Agencies from "./component/Pages/Agencies/Agencies";
import AdCancellationPolicy from "./component/SapPolicies/Ad_Cancellation_Policy/AdCancellationPolicy";
import HowItWorksPage from "./component/Pages/HowItWorks/HowItWorksPage";
import ContactUs from "./component/ContactUs/ContactUs";
import ProductPage from "./component/Pages/ProductPage/ProductPage";
import Brands from "./component/Pages/Brands/Brands";
import BuisnessOwner from "./component/Pages/BuisnessOwner/BuisnessOwner";
import NormalUser from "./component/Pages/NormalUser/NormalUser";
import AboutUs from "./component/Pages/AboutUs/AboutUs";
import NewTabComponent from "./component/TabComponent/NewTabComponent";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/contact_us" element={<ContactUs />} />
          <Route path="/Product" element={<ProductPage />} />
          <Route path="/ContentPolicy" element={<ContentPolicy />} />
          <Route path="/Agency" element={<Agencies />} />
          <Route path="/HowItWorks" element={<HowItWorksPage />} />
          <Route path="/Brands" element={<Brands />} />
          <Route path="/BuisnessOwner" element={<BuisnessOwner />} />

          <Route
            path="/AdCancellationPolicy"
            element={<AdCancellationPolicy />}
          />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicies />} />
          <Route path="/NormalUser" element={<NormalUser />} />
          <Route path="/AboutUs" element={<AboutUs />} />
        </Routes>

        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
