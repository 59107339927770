import React from "react";
import {
  AppBar,
  Grid,
  Toolbar,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";

import logoImage from "../../../assets/Images/Group 6357267.png";
import DrawerTest from "./DrawerTest";
import NewDrawerTest from "./NewDrawerTest";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
const Header = () => {
  // const linksArrays = ["Solutions", "Products", "How It Works", "Company"];
  const linksArrays = [
    { heading: "Solutions", link: "/Agency" },
    { heading: "Products", link: "/" },
    { heading: "How It Works", link: "/HowItWorks" },
    { heading: "Solutions", link: "/" },
  ];
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  console.log(isMd);
  // const solutionData = [
  //   {
  //     heading: "Solutions",
  //     dData: ["Ad-Agencies", "Media-owners", "Brands", "Public"],
  //   },
  // ];

  return (
    <div style={{ width: "100%" }}>
      <AppBar sx={{ background: "#FFFFFF", height: "72px" }} className="header">
        <Toolbar
          className="zeroPadding"
          sx={{
            maxWidth: "90%",
            width: "90%",

            margin: "auto",
          }}
        >
          <Grid container alignItems="center">
            {isMd ? (
              <>
                <Grid item xs={3} alignSelf="center">
                  <Link to="/" style={{ textDecoration: "none" }}>
                    <img src={logoImage} style={{ width: "100%" }}></img>
                  </Link>
                </Grid>
                <NewDrawerTest linksArrays={linksArrays} />
              </>
            ) : (
              <>
                <Grid item xs={3} alignSelf="center">
                  <Link to="/" style={{ textDecoration: "none" }}>
                    <img src={logoImage} width="70%"></img>
                  </Link>
                </Grid>
                <Grid item xs={6} alignSelf="center">
                  <Grid container gap={4} justifyContent="center">
                    <div className="parentText">
                      <Typography
                        className="blackColorTypography headerNav"
                        sx={{
                          position: "relative",
                        }}
                      >
                        Solutions{" "}
                      </Typography>
                      <ul className="list">
                        <li>
                          <NavLink to="/Agency" className="navLink">
                            {" "}
                            Ad-Agencies
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/BuisnessOwner" className="navLink">
                            {" "}
                            Media-Owners
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/Brands" className="navLink">
                            Brands
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/NormalUser" className="navLink">
                            Public
                          </NavLink>
                        </li>
                      </ul>
                    </div>

                    <Link to="/Product" style={{ textDecoration: "none" }}>
                      <Typography className="blackColorTypography">
                        Products
                      </Typography>
                    </Link>
                    <Link to="/HowItWorks" style={{ textDecoration: "none" }}>
                      <Typography className="blackColorTypography">
                        How It Works
                      </Typography>
                    </Link>
                    <div className="parentText">
                      <Typography className="blackColorTypography">
                        Company
                      </Typography>
                      <ul className="list">
                        <li>
                          <NavLink to="/AboutUs" className="navLink">
                            About Us
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/contact_us" className="navLink">
                            Contact Us
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                    gap="1rem"
                  >
                    <Button alignSelf="center">
                      <a
                        href="https://app.postmyad.live"
                        style={{
                          fontSize: "1.6rem",
                          fontWeight: "600",
                          color: "#2F3237",
                          textTransform: "capitalize",
                          textDecoration: "none",
                        }}
                      >
                        Log In
                      </a>
                    </Button>
                    <Link to="/contact_us" style={{ textDecoration: "none" }}>
                      <Button
                        alignSelf="center"
                        className="headerButton"
                        sx={{
                          textTransform: "capitalize",
                        }}
                      >
                        Contact Us
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
