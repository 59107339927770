import React from "react";
import { Container } from "@mui/system";
import {
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
  Box,
  Button,
} from "@mui/material";
import HeroImage from "../../../assets/Images/AboutUS.png";
import IndiaMapImage from "../../../assets/Images/IndianMap.png";
import heroCardImage from "../../../assets/Images/AboutUsCardImage.png";
import heroCardImageTarget from "../../../assets/Images/Target.png";

import GlobleReach from "../../../assets/Images/GlobleReach.png";
import TargettedReach from "../../../assets/Images/TargettedReach.png";
import Economical from "../../../assets/Images/Economical.png";
import RealtimeFeedback from "../../../assets/Images/Real-time Feedback.png";
import DemographicTargeting from "../../../assets/Images/Demographic Targeting.png";
import FrequencyScheduling from "../../../assets/Images/FrequencyScheduling.png";
import AudienceMeasurement from "../../../assets/Images/AudienceMeasurement.png";
import ControlOptimisation from "../../../assets/Images/Control&Optimisation.png";
import Test from "../../Common/test";
import { NavLink } from "react-router-dom";
const AboutUs = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  const cardData = [
    { img: GlobleReach, text: "Global Reach" },
    { img: TargettedReach, text: "Targetted Reach" },
    { img: Economical, text: "Economical" },
    { img: RealtimeFeedback, text: "Real-time Feedback" },
    { img: DemographicTargeting, text: "Demographic Targeting" },
    { img: FrequencyScheduling, text: "Frequency Scheduling" },
    { img: AudienceMeasurement, text: "Audience Measurement" },
    { img: ControlOptimisation, text: "Control Optimisation" },
  ];
  React.useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <Grid
        container
        className="marginBottom100px positionRelative heroImageGrid"
      >
        <Grid item xs={12} md={12} sx={{ height: "100%", width: "100%" }}>
          <img src={HeroImage} className="heroSectionClass widh100" />
        </Grid>
        <Grid
          item
          xs={12}
          className="positionAbsolute BuisnessOwnerHeroSectionDiv"
        >
          <Grid container className="heroTextgap">
            <Grid item xs={12}>
              <Typography className="BuisnessOwnerHeadingFontSize">
                Making Digital Billboard Advertising accessible to everyone
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div>
                <Typography className=" heroSectionParaMarginBottom BuisnessOwnerParaFontSize2">
                  Post my ad is a one stop solution for businesses of all sizes
                  to fulfill their need
                </Typography>
                <Typography className=" BuisnessOwnerParaFontSize2">
                  to be widely seen and heard, efficiently and effectively
                </Typography>
              </div>
            </Grid>
            <Box
              sx={{
                textAlign: "center",
                width: "100%",
              }}
            >
              <NavLink to="/contact_us" style={{ textDecoration: "none" }}>
                <Button className="heroSectionButton">Get in touch</Button>
              </NavLink>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Container maxWidth="xl">
        <Grid container className="widh90 marginBottom100px">
          <Grid item xs={12} md={6}>
            <div style={{ textAlign: "center" }}>
              <img src={IndiaMapImage} style={{ width: "60%" }} />
            </div>
          </Grid>
          <Grid item xs={12} md={6} sx={{ padding: "1rem" }}>
            <Grid container direction="column" gap="2rem">
              <Typography className="heading1">About Post My Ad</Typography>
              <Typography className="subHeading1" sx={{ paddingLeft: "1rem" }}>
                Post MyAd combines some of the world’s latest technologies to
                give you a smart, visually-enhancing and immersive experience.{" "}
              </Typography>
              <Typography className="subHeading1" sx={{ paddingLeft: "1rem" }}>
                It blurs the lines between online & offline advertising.
              </Typography>
              <Typography className="subHeading1" sx={{ paddingLeft: "1rem" }}>
                PostMyAd empowers individuals and businesses to control content
                on digital billboards at anytime, anywhere.{" "}
              </Typography>
              <div style={{ paddingLeft: "4rem" }}>
                <ul>
                  <li>
                    {" "}
                    <Typography className="subHeading1">
                      Upload content from any device
                    </Typography>
                  </li>
                  <li>
                    {" "}
                    <Typography className="subHeading1">
                      Update content in real time or schedule for later
                    </Typography>
                  </li>
                  <li>
                    {" "}
                    <Typography className="subHeading1">
                      Large format LEDs to small tablets, work on any screen
                    </Typography>
                  </li>
                </ul>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent="space-between"
          className="widh90 marginBottom100px"
          rowGap="4rem"
        >
          <Grid
            item
            xs={12}
            sm={5.9}
            sx={{
              padding: "2rem 2rem 5rem 2rem",
              border: "1px solid #d723cd ",
              borderRadius: "20px",
              height: "40rem",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <img
                src={heroCardImage}
                style={{ width: "30%", marginBottom: "2rem" }}
              />
            </div>
            <Grid container direction="column" gap="1rem">
              <Typography className="heading1 fontSize36px textAlignCentre">
                Our Vision
              </Typography>
              <Typography className="subHeading1 fontSize20px textAlignCentre ">
                Promoting Decentralised Advertising which is accessible to one
                and all.
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={5.9}
            sx={{
              padding: "2rem 2rem 5rem 2rem",
              border: "1px solid #d723cd ",
              borderRadius: "20px",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <img
                src={heroCardImageTarget}
                style={{ width: "30%", marginBottom: "2rem" }}
              />
            </div>
            <Grid container direction="column" gap="1rem">
              <Typography className="heading1 fontSize36px textAlignCentre">
                Our mission
              </Typography>
              <Typography className="subHeading1 fontSize20px textAlignCentre">
                To help businesses of all sizes augment their visibility, find
                an audience, engage their clients and build their brand.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Typography className="heading1 fontSize36px textAlignCentre marginBottom30px">
          benefits
        </Typography>
        <Grid
          container
          className="width90 marginBottom100px"
          justifyContent="space-between"
          rowGap="3rem"

          // sx={{ border: "1px solid red" }}
        >
          {cardData.map((cd) => (
            <Grid
              item
              xs={12}
              sm={2.8}
              sx={{
                border: "1px solid black",
                padding: "3rem",
                border: "1px solid #dddddd",
                borderRadius: "2rem",
              }}
            >
              <Grid container direction="column" gap="4rem">
                <div style={{ textAlign: "center", width: "100%" }}>
                  <img src={cd.img} style={{ width: "60%" }} />
                </div>
                <Typography className="subHeading1 fontSize20px textAlignCentre ">
                  {cd.text}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid container className="widh80" gap="4rem">
          <Grid item xs={12}>
            <Typography className="heading1  textAlignCentre">
              Why PDOOH Advertising?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className="subHeading1 textAlignCentre">
              {" "}
              pDOOH gives buyers improved campaign control over traditional DOOH
              by allowing them to stop, start and optimise campaigns, in real
              time with less involvement from the media owner.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className="subHeading1 textAlignCentre">
              For example, an ice cream brand may wish to advertise on screens
              in close proximity to the beach and only on sunny days. Whilst
              location can be delivered today using DOOH, a real time
              transaction at that location when the weather is sunny is only
              possible via pDOOH.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className="subHeading1 textAlignCentre">
              pDOOH also allows buyers to incorporate reporting across all their
              digital media spend, which means they can measure delivery in real
              time and gauge which campaign produces the best results and
              optimise it accordingly.
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Test />
    </>
  );
};

export default AboutUs;
