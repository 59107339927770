import React from "react";
import {
  Typography,
  Grid,
  Breadcrumbs,
  Tabs,
  Tab,
  DialogContent,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import PropTypes from "prop-types";
import TabImage1 from "../../../assets/Images/NormalUserTabImage1.png";
import TabImage2 from "../../../assets/Images/NormalUserTabImage2.png";
import TabImage3 from "../../../assets/Images/NormalUserTabImage3.png";
import TabImage4 from "../../../assets/Images/NormalUserTabImage4.png";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children} </div>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const NormalUserTabComponent1 = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  console.log(isMd);
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  console.log(isMd);

  const tabPanelContent = [
    {
      title: "Select preferred day and time slot to showcase the content",
      img1: TabImage1,
    },
    {
      title: "Select preferred location from multi-screen options.",
      img1: TabImage2,
    },
    {
      title: "Upload multi-format media - an image, video or a slideshow.",
      img1: TabImage3,
    },
    {
      title: "It’s as simple as ordering a pizza.",
      img1: TabImage4,
    },
  ];
  return (
    <div className="marginTop100px">
      <Typography
        className="accordionHeadingTypography fontSize32px Fontcolor2F3237"
        sx={{ marginBottom: "1rem" }}
      >
        How It Works?
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isSm ? (
          <Tabs
            value={value}
            onChange={handleChange}
            className="TabStyleAddCampaign"
            sx={{
              marginBottom: "4rem",
              width: "100%",
              "& .css-ptiqhd-MuiSvgIcon-root": {
                fontSize: "3rem",
              },
            }}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Upload  Ad" className="TabChangesCampaign" />
            <Tab label="Pick Billboard" className="TabChangesCampaign" />
            <Tab label=" Schedule Time" className="TabChangesCampaign" />
            <Tab label="Done" className="TabChangesCampaign" />
          </Tabs>
        ) : (
          <Tabs
            value={value}
            onChange={handleChange}
            className="TabStyleAddCampaign"
            sx={{
              marginBottom: "4rem",
              width: "100%",
              "& .css-ptiqhd-MuiSvgIcon-root": {
                fontSize: "3rem",
              },
            }}
            centered={true}
          >
            <Tab
              label="Upload  Ad"
              style={{
                marginRight: "2rem",
                width: "18%",
                // letterSpacing: "1px",
              }}
              className="TabChangesCampaign"
            />
            <Tab
              label="Pick Billboard"
              style={{
                marginRight: "2rem",
                width: "18%",
                // letterSpacing: "1px",
              }}
              className="TabChangesCampaign"
            />
            <Tab
              label=" Schedule Time"
              style={{
                marginRight: "2rem",
                width: "18%",
                // letterSpacing: "1px",
              }}
              className="TabChangesCampaign"
            />
            <Tab
              label="Done"
              style={{
                marginRight: "2rem",
                width: "18%",
                // letterSpacing: "1px",
              }}
              className="TabChangesCampaign"
            />
          </Tabs>
        )}

        {tabPanelContent.map((a, i) => (
          <TabPanel value={value} index={i} style={{ width: "60%" }}>
            <Typography
              className="adverTiseCardTypography"
              sx={{
                width: "100%",
                margin: "auto",
                textAlign: "center",
                marginBottom: "2rem",
              }}
            >
              {a.title}
            </Typography>

            <Grid container>
              <Grid
                container
                style={{
                  width: "100%",
                  marginInline: "auto",
                }}
                gap="2rem"
                justifyContent="center"
                className="tabImageGap"
              >
                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <img
                      src={a.img1}
                      className="tabImageHeight "
                      style={{
                        width: "100%",
                        height: "60rem",
                        borderRadius: "1.5rem",
                      }}
                    />
                  </Grid>
                </Grid>
                {/* {!isMd && (
                  <Grid item xs={12} md={5.8}>
                    <Grid container justifyContent="center">
                      {" "}
                      <img
                        src={a.img2}
                        className="tabImageHeight"
                        style={{
                          width: "100%",
                          height: "45rem",

                          borderRadius: "1.5rem",
                        }}
                      />{" "}
                    </Grid>
                  </Grid>
                )} */}
              </Grid>
            </Grid>
          </TabPanel>
        ))}
      </div>
    </div>
  );
};

export default NormalUserTabComponent1;
