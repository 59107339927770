import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import TabItem1 from "./TabItem1";
import TabItem2 from "./TabItem2";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", marginTop: "5.6rem " }}>
      <Box>
        <Tabs
          value={value}
          className="howItWorksTab"
          style={{
            width: "50rem",
            marginInline: "auto",
            borderRadius: ".9rem",
          }}
          onChange={handleChange}
          aria-label="basic tabs example"
          centered
          indicatorColor="false"
        >
          <Tab
            label="Campaign"
            {...a11yProps(0)}
            style={{
              fontSize: "2rem",
              borderRadius: ".9rem",
              width: "50%",
              textTransform: "none",
              fontWeight: "600",
              color: "black",
            }}
          />
          <Tab
            label="Advertisment"
            {...a11yProps(1)}
            style={{
              fontSize: "2rem",
              borderRadius: ".9rem",
              width: "50%",
              textTransform: "none",
              fontWeight: "600",
              color: "black",
            }}
          />
        </Tabs>
      </Box>
      <Grid container style={{ marginTop: "5.6rem" }}>
        <TabPanel value={value} index={0}>
          <TabItem1 />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TabItem2 />
        </TabPanel>
      </Grid>
    </Box>
  );
}
