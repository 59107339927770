import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";

const AdCancellationPolicy = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <Grid
        container
        sx={{ marginTop: "72px", padding: "5rem 0" }}
        className="widh90"
        gap="4rem"
      >
        <Grid item xs={12}>
          <Typography className="Content_policy_typography">
            AD Cancellation Policy
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginLeft: "2rem" }}>
          <ul>
            <Grid container direction="column">
              <li>
                <Typography className="Content_policy_Paragraph_typography">
                  SAPS.ai has adopted a client friendly and flexible
                  cancellation policy.
                </Typography>
              </li>
              <li>
                <Typography className="Content_policy_Paragraph_typography">
                  The cancellation can only be done up to 6 hours before the
                  streaming of an ad.
                </Typography>
              </li>
              <li>
                <Typography className="Content_policy_Paragraph_typography">
                  {" "}
                  If you cancel the streaming of your content/ ad. up to 6 hours
                  before the show, 15% of base ad price will be deducted as
                  cancellation fee and the remaining will be refunded to you.
                </Typography>
              </li>
              <li>
                <Typography className="Content_policy_Paragraph_typography">
                  You are allowed to cancel up to 2 content/ad transactions in
                  30 days.
                </Typography>
              </li>
              <li>
                <Typography className="Content_policy_Paragraph_typography">
                  You cannot cancel an ad. if you have applied any discount
                  offers, vouchers or any other loyalty points.
                </Typography>
              </li>
              <li>
                <Typography className="Content_policy_Paragraph_typography">
                  On cancellation of the ad, the Internet handling charges and
                  payment gateway charges will not be refunded.
                </Typography>
              </li>
              <li>
                <Typography className="Content_policy_Paragraph_typography">
                  If you opt for the refund on any other payment source – Credit
                  card/ Debit card or Net banking, the amount will take up to
                  10-15 days to get credited into your account.
                </Typography>
              </li>
              <li>
                <Typography className="Content_policy_Paragraph_typography">
                  We reserve the right to modify/ add/ alter/ revise/
                  discontinue or otherwise carry out any necessary changes to
                  these terms and conditions and/ or the cancellation feature
                  (either wholly or in part).
                </Typography>
              </li>
              <li>
                <Typography className="Content_policy_Paragraph_typography">
                  These terms and conditions are in addition to the terms and
                  conditions and the privacy policy available here ____
                </Typography>
              </li>
            </Grid>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Typography className="Content_policy_Paragraph_typography">
            To avail cancellation, you should be logged in to your SAPS.ai
            account.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default AdCancellationPolicy;
