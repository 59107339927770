import React from "react";
import { Grid, Typography } from "@mui/material";

import footerLogo from "../../../assets/Images/footerLogo.png";
import facebookLogo from "../../../assets/Images/Group 6357904.png";
import instagramLogo from "../../../assets/Images/Group 6357905.png";
import twitterLogo from "../../../assets/Images/Group 6357906.png";
import linkdlnLogo from "../../../assets/Images/Group 6357907.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <Grid
        container
        className="footerGrid"
        sx={{
          padding: "5rem 5rem 3.5rem 5rem",
          backgroundColor: "#000000",
          borderBottom: "1px solid #8894A4",
        }}
      >
        <Grid item xs={12} sm={3} md={2.4}>
          <img
            src={footerLogo}
            className="footerLogoimg"
            width="65%"
            alt="footerLogo"
          />
        </Grid>

        <Grid item xs={12} sm={3} md={2.4}>
          <Typography className="footerHeading">Explore</Typography>
          <Link to="/Brands" style={{ textDecoration: "none" }}>
            <Typography className="footerParagraph">
              PostMyAd For Brands
            </Typography>
          </Link>
          <Link to="/BuisnessOwner" style={{ textDecoration: "none" }}>
            <Typography className="footerParagraph">
              PostMyAd For Business owner
            </Typography>
          </Link>
          <Link to="/Agency" style={{ textDecoration: "none" }}>
            <Typography className="footerParagraph">
              PostMyAd For Ad Agencies
            </Typography>
          </Link>
          <Link to="/NormalUser" style={{ textDecoration: "none" }}>
            <Typography className="footerParagraph">
              PostMyAd For Public
            </Typography>
          </Link>
          <Link to="/HowItWorks" style={{ textDecoration: "none" }}>
            <Typography className="footerParagraph">How Its Work</Typography>
          </Link>
          <Link to="/Product" style={{ textDecoration: "none" }}>
            <Typography className="footerParagraph">Products</Typography>
          </Link>
        </Grid>
        <Grid item xs={12} sm={3} md={2.4}>
          <Typography className="footerHeading">Company</Typography>
          <Link to="/AboutUs" style={{ textDecoration: "none" }}>
            <Typography className="footerParagraph">About Us</Typography>
          </Link>
          <Link to="/contact_us" style={{ textDecoration: "none" }}>
            {" "}
            <Typography className="footerParagraph">Contact Us</Typography>
          </Link>

          {/* <Typography className="footerParagraph">Careers</Typography> */}
          <Link to="/PrivacyPolicy" style={{ textDecoration: "none" }}>
            <Typography className="footerParagraph">Privacy Policy</Typography>
          </Link>
          <Link to="/AdCancellationPolicy" style={{ textDecoration: "none" }}>
            <Typography className="footerParagraph">
              Ad Cancellation Policy
            </Typography>
          </Link>
          <Link to="/ContentPolicy" style={{ textDecoration: "none" }}>
            <Typography className="footerParagraph">Content Policy</Typography>
          </Link>
        </Grid>
        <Grid item xs={12} sm={3} md={2.4}>
          <Typography className="footerHeading">Contact</Typography>
          <Typography
            className="footerParagraph textTransformNone "
            sx={{
              borderBottom: "1px solid #cccccc",
              width: "80%",
              marginBottom: "10px",
            }}
          >
            hello@postmyad.ai
          </Typography>

          <Typography className="footrSubheading">INDIA :</Typography>
          <Typography
            className="footerParagraph"
            sx={{
              borderBottom: "1px solid #cccccc",
              width: "80%",
              marginBottom: "10px",
            }}
          >
            Office No. 507, 5th Floor, ABW Tower, IFFCO Chowk, Gurgaon, Haryana
            122022
          </Typography>

          <Typography className="footrSubheading">DUBAI:</Typography>
          <Typography className="footerParagraph">
            Office No. 1616, 16th Floor The Metropolis Tower 1907 Burj Khalifa
            Blvd - Business Bay Dubai - PO BOX - 9437
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3} md={2.4}>
          <Typography className="footerHeading">Join Us</Typography>
          <Grid container gap="1.5rem">
            {/* <a
              style={{
                textDecoration: "none",
                border: "1px solid red",
              }}
              href="https://www.google.com/"
            > */}
            <img src={facebookLogo} />
            {/* </a> */}
            <img src={instagramLogo} />
            <img src={twitterLogo} />
            <img src={linkdlnLogo} />
          </Grid>
        </Grid>
      </Grid>
      <Typography
        className="footerParagraph"
        sx={{
          textAlign: "center",
          backgroundColor: "#000000",
          padding: "2rem 0 .5rem 0",
        }}
      >
        Copyright@ 2022 AI II edge technologies Pvt Ltd All rights reserved
      </Typography>
    </div>
  );
};

export default Footer;
