import { Typography, Grid } from "@mui/material";
import React from "react";

const Card = ({ text, cardContent }) => {
  return (
    <div style={{ margin: "8rem 0" }}>
      <Grid container className="widh90" alignItems="center">
        <Grid item xs={12}>
          <Typography
            className="fontSize32px fontWeight600 Fontcolor2f3237 textAlignCentre lineHeight30px"
            sx={{ padding: "3rem 0" }}
          >
            {text}
          </Typography>
        </Grid>
        <Grid container justifyContent="space-between" gap="2rem">
          {cardContent.map((a) => (
            <Grid
              item
              xs={12}
              sm={5.5}
              lg={3.8}
              className="adverTiseCards"
              sx={{ padding: "5rem 2rem", height: "35rem" }}
            >
              <Grid container gap="2rem" alignItems="center">
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <img src={a.img} alt="cardImage" />
                </Grid>
                <Grid item xs={12}>
                  <Typography className="textAlignCentre adverTiseCardTypography">
                    {a.text}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default Card;
