import {
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  Box,
  Button,
} from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import HeroImage from "../../../assets/Images/brandsHeroImage.png";
import AgencyCardImage from "../../../assets/Images/SC1.png";
import brand1 from "../../../assets/Images/brands1.png";
import brand2 from "../../../assets/Images/brands2.png";
import brand3 from "../../../assets/Images/brands4Img.png";
import brands4 from "../../../assets/Images/brands5Img.png";
import brand5 from "../../../assets/Images/brands6Img.png";
import cardImage1 from "../../../assets/Images/b1.png";
import cardImage2 from "../../../assets/Images/b2.png";
import cardImage3 from "../../../assets/Images/b3.png";
import cardImage4 from "../../../assets/Images/b4.png";
import cardImage5 from "../../../assets/Images/b5.png";
import cardImage6 from "../../../assets/Images/b6.png";
import Test from "../../Common/test";
import { NavLink } from "react-router-dom";
const Brands = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  const cardData = [
    {
      image: cardImage2,
      text: "Adapt To Changing Behaviors",
      overLayText1: "Adapt To Changing Behaviors",
      overLayText2:
        " postmtad provides real-time availability of premium inventory and helps you get your campaign live immediately so you can respond to external factors last (eg. breakingnews, weather forecast).",
    },
    {
      image: cardImage1,
      text: "Boost performance quickly",
      overLayText1: "Adapt To Changing Behaviors",
      overLayText2:
        "postmtad provides real-time availability of premium inventory and helps you get your campaign live immediately so you can respond to external factors last (eg. breaking news, weather forecast).",
    },
    {
      image: cardImage5,
      text: "Align integrated campaigns",
      overLayText1: "Unskippable and high impact",
      overLayText2:
        "Complete control over your digital OOH activities and real-time data from multiple sources, making it easier to plan and align campaign details for maximum impact",
    },
    {
      image: cardImage4,
      text: "Unskippable and high impact ",
      overLayText1: "Unskippable and high impact",
      overLayText2:
        "Place advertisements that are always viewable and create a high impact to deliver results.",
    },
    {
      image: cardImage6,
      text: "Real-time performance",
      overLayText1: "Adapt To Changing Behaviors",
      overLayText2:
        "postmyad campaign performance analytics help you see what is (and what isn't) working so you can optimize campaigns in real-time for better impact and improved ROI.",
    },
    {
      image: cardImage3,
      text: "Easy-to-use platform",
      overLayText1: "Easy-to-use platform",
      overLayText2:
        "Designed to be user-friendly and easy to get results as quickly as possible, supporting your test and learn approach.",
    },
  ];
  React.useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <Grid container className="positionRelative heroImageGrid">
        <Grid item xs={12} md={12} sx={{ height: "100%", width: "100%" }}>
          <img src={HeroImage} className="heroSectionClass widh100" />
        </Grid>
        <Grid
          item
          xs={12}
          className="positionAbsolute BuisnessOwnerHeroSectionDiv"
        >
          <Grid container className="heroTextgap">
            <Grid item xs={12}>
              <Typography className="BuisnessOwnerHeadingFontSize">
                Enhance Your Brand Visibility with PDOOH Ads
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div>
                {/* <Typography className=" heroSectionParaMarginBottom BuisnessOwnerParaFontSize2">
                The Right Placement, 
For The Right Audience

                </Typography> */}
                <Typography className=" BuisnessOwnerParaFontSize2">
                  The Right Placement, For The Right Audience
                </Typography>
              </div>
            </Grid>
            <Box
              sx={{
                textAlign: "center",
                width: "100%",
              }}
            >
              <NavLink to="/contact_us" style={{ textDecoration: "none" }}>
                <Button className="heroSectionButton">Request A Demo</Button>
              </NavLink>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Container maxWidth="xl">
        <Grid
          container
          className={
            isMd ? "widh90 marginTop50px gap20px" : "widh90 marginTop100px"
          }
          justifyContent="space-between"
        >
          <Grid item xs={12} md={5.5}>
            <Grid
              container
              gap="3rem"
              direction="column"
              justifyContent="center"
              sx={{ height: "100%" }}
            >
              <Typography className="heading1">
                {" "}
                Self-Management of Ads
              </Typography>
              <Typography className="subHeading1">
                PostMyAd is world’s first DIY app-based platform for
                programmatic digital out of home advertising (PDOOH), enabling
                self-management of ads and ad campaigns from anywhere. It Allows
                businesses of all sizes to independently plan and launch their
                ad campaigns on street billboards in real-time. What Where and
                When are all in your hands.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={5.5}>
            <img src={brand1} alt="agencyCardImage" className="widh100 " />
          </Grid>
        </Grid>
        <Grid
          container
          className={
            isMd ? "widh90 marginTop50px gap20px" : "widh90 marginTop100px"
          }
          justifyContent="space-between"
        >
          <Grid item xs={12} md={5.5} order={{ xs: 2, md: 1 }}>
            <img src={brand2} alt="agencyCardImage" className="widh100 " />
          </Grid>
          <Grid item xs={12} md={5.5} order={{ xs: 1, md: 1 }}>
            <Grid
              container
              gap="3rem"
              direction="column"
              justifyContent="center"
              sx={{ height: "100%" }}
            >
              <Typography className="heading1">
                {" "}
                Wide Geographical coverage
              </Typography>
              <Typography className="subHeading1">
                Targeting the right audience at the right place is paramount in
                driving sales. With our wide network of digital billboards, we
                provide you with a unique opportunity to ensure your brand gets
                the attention it strives for.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className="marginTop100px marginBottom50px">
          <Grid item xs={12}>
            <Typography className="heading1 textAlignCentre">
              how PDOOH advertising Works
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className="subHeading1 textAlignCentre">
              Using our simple process, you’ll be up and running in seconds.
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          sx={{ padding: "4rem 0rem" }}
          className="widh90  cardGapcontainer"
          justifyContent="space-between"
        >
          <Grid
            className="card "
            item
            xs={12}
            md={3.8}
            sx={{
              padding: "4rem 3rem",
            }}
          >
            <Grid
              container
              className="cardGap"
              justifyContent="center"
              alignItems="center"
              direction="column"
              // gap="2rem"
            >
              <div className="cardCircleNo">
                <Typography className="fontSize32px fontWeight500">
                  1
                </Typography>
              </div>
              <Typography
                className={
                  isSm
                    ? "cardTypographyHeading fontSize20px Fontcolor2f3237"
                    : "cardTypographyHeading fontSize20px marginBottom20px Fontcolor2f3237"
                }
              >
                Upload Creative
              </Typography>
              <Typography
                className={
                  isSm
                    ? "cardTypographyParagraph widh90"
                    : "cardTypographyParagraph"
                }
                sx={{ marginTop: "1rem" }}
              >
                It may be a showreel, a video, an animation or a catchy graphic,
                PostMyAd Propagates it all.
              </Typography>
            </Grid>
          </Grid>
          <Grid
            className="card "
            item
            xs={12}
            md={3.8}
            sx={{ padding: "4rem 3rem" }}
          >
            <Grid
              container
              className="cardGap"
              justifyContent="center"
              alignItems="center"
              direction="column"
              // gap="2rem"
            >
              <div className="cardCircleNo">
                <Typography className="fontSize32px fontWeight500">
                  2
                </Typography>
              </div>
              <Typography
                className={
                  isSm
                    ? "cardTypographyHeading fontSize20px Fontcolor2f3237"
                    : "cardTypographyHeading fontSize20px marginBottom20px Fontcolor2f3237"
                }
              >
                Select Billboard & Schedule Time
              </Typography>
              <Typography
                className={
                  isSm
                    ? "cardTypographyParagraph widh90 "
                    : "cardTypographyParagraph"
                }
                sx={{ marginTop: "1rem" }}
              >
                Pick your billboard location (retail stores, Coffee Shops or
                Restaurants, parking lots, Travel Transit Points, etc.) Pick
                days, time slots and duration of your campaign, do it as per
                your budget.
              </Typography>
            </Grid>
          </Grid>
          <Grid
            className="card "
            item
            xs={12}
            md={3.8}
            sx={{ padding: "4rem 3rem" }}
          >
            <Grid
              container
              className="cardGap"
              justifyContent="center"
              alignItems="center"
              direction="column"
              // gap="2rem"
            >
              <div className="cardCircleNo">
                <Typography className="fontSize32px fontWeight500">
                  3
                </Typography>
              </div>
              <Typography
                className={
                  isSm
                    ? "cardTypographyHeading fontSize20px Fontcolor2f3237"
                    : "cardTypographyHeading fontSize20px marginBottom20px Fontcolor2f3237"
                }
              >
                Stream & Measure
              </Typography>
              <Typography
                className={
                  isSm
                    ? "cardTypographyParagraph widh90"
                    : "cardTypographyParagraph"
                }
                sx={{ marginTop: "1rem" }}
              >
                Once your creative goes live, you can Assess the impressions
                received and the eyeballs caught in real time - strategize for
                better impact and ROI.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          className={
            isMd ? "widh90 marginTop50px gap20px" : "widh90 marginTop100px"
          }
          justifyContent="space-between"
        >
          <Grid item xs={12} md={5.5}>
            <Grid
              container
              gap="3rem"
              direction="column"
              justifyContent="center"
              sx={{ height: "100%" }}
            >
              <Typography className="heading1">
                {" "}
                Real-time audience Feedback
              </Typography>
              <Typography className="subHeading1">
                Measure audience reactions through real-time analytics and
                quickly optimize campaigns without losing time and money.
                Leverage data for hyper targeting.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={5.5}>
            <img src={brands4} alt="agencyCardImage" className="widh100 " />
          </Grid>
        </Grid>

        <Grid
          container
          className={
            isMd ? "widh90 marginTop50px gap20px" : "widh90 marginTop100px"
          }
          justifyContent="space-between"
        >
          <Grid item xs={12} md={5.5} order={{ xs: 2, md: 1 }}>
            <img src={brand3} alt="agencyCardImage" className="widh100 " />
          </Grid>
          <Grid item xs={12} md={5.5} order={{ xs: 1, md: 1 }}>
            <Grid
              container
              gap="3rem"
              direction="column"
              justifyContent="center"
              sx={{ height: "100%" }}
            >
              <Typography className="heading1">
                {" "}
                Flexible Scheduling Options
              </Typography>
              <Typography className="subHeading1">
                Plan Ad Campaigns months in advance or go live in minutes.
                Schedule content to play at any time and date with the click of
                a button. Show your ads only during the specific time of days or
                dates that prove to bring the highest ROI for your brand.
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          className={
            isMd ? "widh90 marginTop50px gap20px" : "widh90 marginTop100px"
          }
          justifyContent="space-between"
        >
          <Grid item xs={12} md={5.5}>
            <Grid
              container
              gap="3rem"
              direction="column"
              justifyContent="center"
              sx={{ height: "100%" }}
            >
              <Typography className="heading1">
                {" "}
                High engagement rates
              </Typography>
              <Typography className="subHeading1">
                Increase audience interaction and ad recall by an average of
                3-15% with creatives that gamify your advertising experience.
                Make your customers interact with your campaigns to skyrocket
                your ROI.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={5.5}>
            <img src={brand5} alt="agencyCardImage" className="widh100 " />
          </Grid>
        </Grid>
        <Grid container sx={{ margin: "10rem 0" }}>
          <Grid item xs={12}>
            <Typography className="mediumHeading">
              postMyAd for all size of brands{" "}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          gap="4rem"
          className="widh80"
          //   sx={{ border: "1px solid red" }}
        >
          <Grid item xs={12}>
            <Grid container justifyContent="space-between" rowGap="4rem">
              {cardData.map((cd) => (
                <Grid
                  item
                  xs={12}
                  md={5.5}
                  lg={3.5}
                  className="parent boxShadow1"
                >
                  <Grid
                    container
                    direction="column"
                    // className="boxShadow1 "
                    sx={{
                      padding: "2rem 2rem 4rem 2rem",
                    }}
                    gap="2rem"
                  >
                    <div style={{ textAlign: "center", width: "100%" }}>
                      <img
                        src={cd.image}
                        style={{ width: "70%", borderRadius: "2rem" }}
                      />
                    </div>
                    <Typography
                      className="subHeading1 fontWeight600 Fontcolor2F3237"
                      sx={{ textAlign: "center" }}
                    >
                      {cd.text}
                    </Typography>
                  </Grid>
                  <div className="child">
                    <Typography
                      className="subHeading1 fontWeight600 FontColorf6f6f6"
                      sx={{ textAlign: "center", marginBottom: "3rem" }}
                    >
                      {" "}
                      {cd.overLayText1}
                    </Typography>
                    <Typography
                      className="whiteColorTypography "
                      sx={{ textAlign: "center" }}
                    >
                      {cd.overLayText2}
                    </Typography>
                  </div>
                </Grid>
              ))}

              {/* <Grid item xs={12} sm={3.5}>
                <Grid
                  container
                  direction="column"
                  className="boxShadow1"
                  sx={{
                    padding: "2rem 2rem 4rem 2rem",
                  }}
                  gap="2rem"
                >
                  <div style={{ textAlign: "center", width: "100%" }}>
                    <img src={cardImage1} style={{ width: "100%" }} />
                  </div>
                  <Typography
                    className="subHeading1 fontWeight600 Fontcolor2F3237"
                    sx={{ textAlign: "center" }}
                  >
                    Boost performance quickly
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={3.5}>
                <Grid
                  container
                  direction="column"
                  className="boxShadow1"
                  sx={{
                    padding: "2rem 2rem 4rem 2rem",
                  }}
                  gap="2rem"
                >
                  <div style={{ textAlign: "center", width: "100%" }}>
                    <img src={cardImage4} style={{ width: "100%" }} />
                  </div>
                  <Typography
                    className="subHeading1 fontWeight600 Fontcolor2F3237"
                    sx={{ textAlign: "center" }}
                  >
                    Unskippable and high impact
                  </Typography>
                </Grid>
              </Grid> */}
            </Grid>
          </Grid>
          {/* <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Grid item xs={12} sm={3.5}>
                <Grid
                  container
                  direction="column"
                  className="boxShadow1"
                  sx={{
                    padding: "2rem 2rem 4rem 2rem",
                  }}
                  gap="2rem"
                >
                  <div style={{ textAlign: "center", width: "100%" }}>
                    <img src={cardImage6} style={{ width: "100%" }} />
                  </div>
                  <Typography
                    className="subHeading1 fontWeight600 Fontcolor2F3237"
                    sx={{ textAlign: "center" }}
                  >
                    Real-time performance
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={3.5}>
                <Grid
                  container
                  direction="column"
                  className="boxShadow1"
                  sx={{
                    padding: "2rem 2rem 4rem 2rem",
                  }}
                  gap="2rem"
                >
                  <div style={{ textAlign: "center", width: "100%" }}>
                    <img src={cardImage1} style={{ width: "100%" }} />
                  </div>
                  <Typography
                    className="subHeading1 fontWeight600 Fontcolor2F3237"
                    sx={{ textAlign: "center" }}
                  >
                    Easy-To-Use Platform
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={3.5}>
                <Grid
                  container
                  direction="column"
                  className="boxShadow1"
                  sx={{
                    padding: "2rem 2rem 4rem 2rem",
                  }}
                  gap="2rem"
                >
                  <div
                    style={{
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    <img src={cardImage3} style={{ width: "100%" }} />
                  </div>
                  <Typography
                    className="subHeading1 fontWeight600 Fontcolor2F3237"
                    sx={{ textAlign: "center" }}
                  >
                    Align integrated campaigns
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
        <Grid container sx={{ margin: "10rem 0 5rem 0" }}>
          <Grid item xs={12}>
            <Typography className="mediumHeading">
              Key Benefits of PostMyAd for brands
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          className="widh80"
          // sx={{ border: "1px solid red" }}
          gap="4rem"
          sx={{ marginBottom: "5rem" }}
        >
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Grid
                item
                xs={12}
                md={5.8}
                sx={{
                  borderRight: "1px solid #dddddd",
                  borderBottom: "1px solid #dddddd",
                  height: "20rem",
                }}
              >
                <Grid
                  container
                  sx={{ height: "100%", alignItems: "center", padding: "2rem" }}
                >
                  <Typography className="subHeading1 textAlignCentre fontWeight600">
                    {" "}
                    Flexibility to start, Stop and optimise campaigns instantly
                    without financial impact{" "}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={5.8}
                sx={{
                  borderLeft: "1px solid #dddddd",
                  borderBottom: "1px solid #dddddd",
                  height: "20rem",
                }}
              >
                <Grid
                  container
                  sx={{ height: "100%", alignItems: "center", padding: "2rem" }}
                >
                  <Typography className="subHeading1 textAlignCentre fontWeight600">
                    {" "}
                    Automated workflows improve efficiencies for both buyers and
                    sellers
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Grid
                item
                xs={12}
                md={5.8}
                sx={{
                  borderTop: "1px solid #dddddd",
                  borderRight: "1px solid #dddddd",
                  height: "20rem",
                }}
              >
                <Grid
                  container
                  sx={{ height: "100%", alignItems: "center", padding: "2rem" }}
                >
                  <Typography className="subHeading1 textAlignCentre fontWeight600">
                    {" "}
                    Hyper Targeting via demographic, geography, income level
                    etc. Visuals can also be adapted to context and audience.
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={5.8}
                sx={{
                  borderLeft: "1px solid #dddddd",
                  borderTop: "1px solid #dddddd",
                  height: "20rem",
                }}
              >
                <Grid
                  container
                  sx={{ height: "100%", alignItems: "center", padding: "2rem" }}
                >
                  <Typography className="subHeading1 textAlignCentre fontWeight600">
                    {" "}
                    full process transparency and intelligent analytics to
                    better understand campaign commercials. Track key KPI’s,
                    including ad spend and ROI using smart dashboards and custom
                    reporting.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Test />
    </>
  );
};

export default Brands;
