import {
  Typography,
  Grid,
  Button,
  useTheme,
  useMediaQuery,
  Box,
} from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import ReactPlayer from "react-player";
import heroImage from "../../../../assets/Images/howitswork.png";
import howItWorks from "../../../../assets/Video/hiwAdverTab1.mp4";
import howItWorks2 from "../../../../assets/Video/hiwAdverTab2.mp4";
import howItWorks3 from "../../../../assets/Video/hiwAdverTab3.mp4";
import howItWorks4 from "../../../../assets/Video/hiwAdverTab4.mp4";
import { NavLink } from "react-router-dom";

const TabItem2 = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Grid
        container
        className={isMd ? "widh90 gap30px" : "widh90"}
        sx={{ marginTop: "5.6rem" }}
      >
        <Grid item xs={12} md={6}>
          <Grid
            container
            sx={{ height: "100%" }}
            className="widh80"
            direction="column"
            justifyContent="center"
            gap="2rem"
          >
            <Typography className="fontSize20px fontWeight600 FontColor8894A4">
              Step 1
            </Typography>
            <Typography className="heading1">
              select your target venue
            </Typography>
            <Typography className="subHeading1">
              Each unit in our system has details on impressions, historical
              cost, previous advertisers, and demographics that we collect from
              a number of different sources, so you can know if it’s the unit
              for your campaign.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          {/* <img src={pc1} style={{ width: "100%", height: "100%" }} /> */}

          <ReactPlayer
            url={howItWorks}
            vimeoConfig={{ iframeParams: { fullscreen: 0 } }}
            width={"100%"}
            height={"100%"}
            controls={true}
            loop={true}
            config={{
              file: {
                attributes: {
                  autoPlay: true,
                  muted: true,
                },
              },
            }}
          />
        </Grid>
      </Grid>

      <Grid
        container
        className={isMd ? "widh90 gap30px" : "widh90"}
        sx={{ marginTop: "10rem" }}
      >
        <Grid item xs={12} md={6}>
          {/* <img src={pc1} style={{ width: "100%", height: "100%" }} /> */}

          <ReactPlayer
            url={howItWorks2}
            vimeoConfig={{ iframeParams: { fullscreen: 0 } }}
            width={"100%"}
            height={"100%"}
            controls={true}
            loop={true}
            config={{
              file: {
                attributes: {
                  autoPlay: true,
                  muted: true,
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid
            container
            sx={{ height: "100%" }}
            className="widh80"
            direction="column"
            justifyContent="center"
            gap="2rem"
          >
            <Typography className="fontSize20px fontWeight600 FontColor8894A4">
              Step 2
            </Typography>
            <Typography className="heading1 ">Select Billboards</Typography>
            <Typography className="subHeading1">
              choose a location based on types of audiences, custom audiences,
              specific locations or points of interest
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        className={isMd ? "widh90 gap30px" : "widh90"}
        sx={{ marginTop: "10rem" }}
      >
        <Grid item xs={12} md={6}>
          <Grid
            container
            sx={{ height: "100%" }}
            className="widh80"
            direction="column"
            justifyContent="center"
            gap="2rem"
          >
            <Typography className="fontSize20px fontWeight600 FontColor8894A4">
              Step 3
            </Typography>
            <Typography className="heading1">
              Schedule & upload content{" "}
            </Typography>
            <Typography className="subHeading1">
              design your own ad or choose from our library of ad templates or
              hire creative services from our CreativeHub
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          {/* <img src={pc1} style={{ width: "100%", height: "100%" }} /> */}

          <ReactPlayer
            url={howItWorks3}
            vimeoConfig={{ iframeParams: { fullscreen: 0 } }}
            width={"100%"}
            height={"100%"}
            controls={true}
            loop={true}
            config={{
              file: {
                attributes: {
                  autoPlay: true,
                  muted: true,
                },
              },
            }}
          />
        </Grid>
      </Grid>

      <Grid
        container
        className={isMd ? "widh90 gap30px" : "widh90"}
        sx={{ marginTop: "10rem" }}
      >
        <Grid item xs={12} md={6}>
          {/* <img src={pc1} style={{ width: "100%", height: "100%" }} /> */}

          <ReactPlayer
            url={howItWorks4}
            vimeoConfig={{ iframeParams: { fullscreen: 0 } }}
            width={"100%"}
            height={"100%"}
            controls={true}
            loop={true}
            config={{
              file: {
                attributes: {
                  autoPlay: true,
                  muted: true,
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid
            container
            sx={{ height: "100%" }}
            className="widh80"
            direction="column"
            justifyContent="center"
            gap="2rem"
          >
            <Typography className="fontSize20px fontWeight600 FontColor8894A4">
              Final
            </Typography>
            <Typography className="heading1 ">payment & publish ad</Typography>
            <Typography className="subHeading1">
              Add one or more date and time ranges for your campaign
            </Typography>
            <div style={{ marginTop: "4rem" }}>
              <Button className="btn">Post My Add Now</Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="column" sx={{ padding: "10rem" }}>
        <Typography className="textAlignCentre fontSize48px fontWeight600 Fontcolor2f3237">
          PostMyAd is revolutionizing digital{" "}
        </Typography>
        <Typography className="textAlignCentre fontSize48px fontWeight600 Fontcolor2f3237">
          marketing and giving you more.{" "}
        </Typography>
        <Typography className="textAlignCentre fontSize48px fontWeight600 Fontcolor2f3237">
          {" "}
          Are you ready?{" "}
        </Typography>
      </Grid>
    </>
  );
};

export default TabItem2;
