import React from "react";
import {
  Typography,
  Grid,
  Breadcrumbs,
  Tabs,
  Tab,
  DialogContent,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { createMuiTheme } from "@mui/material";
import PropTypes from "prop-types";
import TabImage1 from "../../assets/Images/Rectangle 8840.png";
import TabImage2 from "../../assets/Images/Rectangle 8841.png";
import TabImage3 from "../../assets/Images/Rectangle 8842.png";
import TabImage4 from "../../assets/Images/Rectangle 8843.png";
import TabImage5 from "../../assets/Images/Rectangle 8844.png";
import TabImage6 from "../../assets/Images/Rectangle 8845.png";
import TabImage7 from "../../assets/Images/Rectangle 8846.png";
import TabImage8 from "../../assets/Images/Rectangle 8847.png";
import TabImage9 from "../../assets/Images/Rectangle 8836.png";
import TabImage10 from "../../assets/Images/Rectangle 8839.png";
import { Box } from "@mui/system";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children} </div>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const TabComponent2 = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const theme = createMuiTheme({
    breakpoints: {
      values: {
        xs: 0,
        tiny: 300,
        sm: 810,
        md: 900,
        lg: 1280,
        xl: 1920,
      },
    },
  });

  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  console.log(isMd);
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  console.log(isMd);

  const tabPanelContent = [
    {
      title:
        "Real estate agents are neighborhood experts—so are we. We know the best locations to advertise your agency, and where those looking to buy properties are paying attention. Join the hundreds of realtors in india who use OOH to elevate their brand awareness.",
      img1: TabImage1,
      img2: TabImage2,
    },
    {
      title:
        "Hospitals tend to advertise to earn trust and and gain name recognition from their local community. OOH can help hospitals sustain genuine connections with those seeking healthcare providers in their specific area of india.",
      img1: TabImage3,
      img2: TabImage4,
    },
    {
      title:
        "More and more hotels and restaurants discover the advantages of digital billboards. Digital screens are an ideal way to inform customers and can contribute to the atmosphere and customer experience in hotels, restaurants, cafes and bars. Thanks to our extensive experience with digital signage applications in catering and hospitality, we can offer tailor-made solutions for every company: from a cozy bar to an international chain of hotels. Discover our smart digital billboards solutions and excellent service.",
      img1: TabImage5,
      img2: TabImage6,
    },
    {
      title:
        "Hospitals tend to advertise to earn trust and and gain name recognition from their local community. OOH can help hospitals sustain genuine connections with those seeking healthcare providers in their specific area of Chicago.",
      img1: TabImage7,
      img2: TabImage8,
    },
    {
      title:
        "With corporate office digital billboards from postMyAd, you’ll be able to push out concise, targeted messages that can be consumed at a glance. These billboards guide staff and visitors with content that makes day-to-day tasks easier and adds value to your office space.",
      img1: TabImage9,
      img2: TabImage10,
    },
  ];
  return (
    <div>
      <Typography
        className="accordionHeadingTypography fontSize32px Fontcolor2F3237"
        sx={{ marginBottom: "1rem" }}
      >
        Ideal locations to Advertise
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isSm ? (
          <Tabs
            value={value}
            onChange={handleChange}
            className="TabStyleAddCampaign"
            sx={{
              marginBottom: "5rem",
              width: "100%",
              "& .css-ptiqhd-MuiSvgIcon-root": {
                fontSize: "3rem",
              },
            }}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Real Estate" className="TabChangesCampaign" />
            <Tab label="Hospitals" className="TabChangesCampaign" />
            <Tab label=" Hospitality" className="TabChangesCampaign" />
            <Tab label="Retail Outlets" className="TabChangesCampaign" />
            <Tab label="Corporate Offices" className="TabChangesCampaign" />
          </Tabs>
        ) : (
          <Tabs
            value={value}
            onChange={handleChange}
            className="TabStyleAddCampaign"
            sx={{
              marginBottom: "5rem",
              width: "100%",
              "& .css-ptiqhd-MuiSvgIcon-root": {
                fontSize: "3rem",
              },
            }}
            centered={true}
          >
            <Tab label="Real Estate" className="TabChangesCampaign" />
            <Tab label="Hospitals" className="TabChangesCampaign" />
            <Tab label=" Hospitality" className="TabChangesCampaign" />
            <Tab label="Retail Outlets" className="TabChangesCampaign" />
            <Tab label="Corporate Offices" className="TabChangesCampaign" />
          </Tabs>
        )}

        {tabPanelContent.map((a, i) => (
          <TabPanel value={value} index={i}>
            <Typography
              className="adverTiseCardTypography"
              sx={{
                width: "80%",
                margin: "auto",
                textAlign: "center",
                marginBottom: "5rem",
              }}
            >
              {a.title}
            </Typography>

            <Grid container>
              <Grid
                container
                style={{
                  width: "90%",
                  marginInline: "auto",
                }}
                gap="2rem"
                justifyContent="center"
                className="tabImageGap"
              >
                <Grid item xs={12} md={5.8}>
                  <Grid container justifyContent="center">
                    <img
                      src={a.img1}
                      className="tabImageHeight tabImageWidth"
                      style={{
                        width: "100%",
                        height: "45rem",
                        borderRadius: "1.5rem",
                      }}
                    />
                  </Grid>
                </Grid>
                {!isMd && (
                  <Grid item xs={12} md={5.8}>
                    <Grid container justifyContent="center">
                      {" "}
                      <img
                        src={a.img2}
                        className="tabImageHeight"
                        style={{
                          width: "100%",
                          height: "45rem",

                          borderRadius: "1.5rem",
                        }}
                      />{" "}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </TabPanel>
        ))}
      </div>
    </div>
  );
};

export default TabComponent2;
