import * as React from "react";
import { Button, Grid, InputBase, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import contactUsLogo from "../../assets/Images/PMA_map_pin1 (1) 1.png";
const ContactUs = () => {
  React.useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <Grid container sx={{ padding: "10rem 0 8rem 0" }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              sx={{ padding: "1rem" }}
              className="textAlignCentre fontSize48px fontWeight500 fontColorD723cd"
            >
              Contact us
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              className="fontSize20px textAlignCentre fontWeight500"
              sx={{ padding: "1rem" }}
            >
              Want us to demo our platform to you and your team? Do you have a
              question? Get in touch.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          className="widh90 marginTop50px contactFormText"
          sx={{ padding: "3rem 0" }}
        >
          <Grid item xs={12} sm={5}>
            <div style={{ textAlign: "center" }}>
              <img src={contactUsLogo} style={{ width: "50%" }} />
            </div>
          </Grid>
          <Grid item xs={12} sm={7}>
            <Grid container sx={{ padding: "5rem 2rem" }} gap="6rem">
              <Grid item xs={12}>
                <Grid container gap="1rem">
                  <Grid item xs={5.5}>
                    <InputBase
                      className="input-base"
                      placeholder=" Your Name"
                    />
                  </Grid>
                  <Grid item xs={5.5}>
                    <InputBase
                      className="input-base"
                      placeholder=" Your Email"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container gap="1rem">
                  <Grid item xs={5.5}>
                    <InputBase
                      className="input-base"
                      placeholder=" Phone Number"
                    />
                  </Grid>
                  <Grid item xs={5.5}>
                    <InputBase className="input-base" placeholder="Country" />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container gap="1rem">
                  <Grid item xs={5.5}>
                    <InputBase
                      className="input-base"
                      placeholder=" Company Name "
                    />
                  </Grid>
                  <Grid item xs={5.5}>
                    <InputBase className="input-base" placeholder="I am" />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={11.2}>
                <InputBase className="input-base" placeholder=" Message  " />
              </Grid>
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <Button className="submit_btn ">Send</Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ContactUs;
