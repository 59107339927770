import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";

const ContentPolicy = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <Grid
        container
        sx={{ marginTop: "72px", padding: "5rem 0" }}
        className="widh90"
        gap="4rem"
      >
        <Grid item xs={12}>
          <Typography className="Content_policy_typography">
            Content Policy
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginLeft: "2rem" }}>
          <ul>
            <Grid container gap="4rem" direction="column">
              <li>
                <Typography className="Content_policy_Paragraph_typography">
                  This policy pertains to any information, text, links,
                  graphics, photos, audio, videos, digital art or art of any
                  form, NFTs or other materials or arrangements of materials
                  uploaded, downloaded or appearing on the Services
                  (collectively referred to as “Content”).
                </Typography>
              </li>
              <li>
                <Typography className="Content_policy_Paragraph_typography">
                  SAPS.ai reserves the right to remove content that violates our
                  rules and policies, including for example, copyright or
                  trademark violations or other intellectual property
                  misappropriation, impersonation, unlawful conduct, or
                  harassment. Information regarding specific policies and the
                  process for reporting or appealing violations can be found in
                  our Help Center. If you believe that your Content has been
                  copied in a way that constitutes copyright infringement,
                  please report this by visiting the Help Center.
                </Typography>
              </li>
              <li>
                <Typography className="Content_policy_Paragraph_typography">
                  {" "}
                  All Content is the sole responsibility of the person who
                  originated such Content. We may not monitor or control the
                  Content posted on the SAPS.ai platform and we cannot and do
                  not take the responsibility for such Content
                </Typography>
              </li>
            </Grid>
          </ul>
        </Grid>
      </Grid>
    </>
  );
};

export default ContentPolicy;
