import {
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  Box,
  Button,
} from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import HeroImage from "../../../assets/Images/BuisnessOwnerHero.png";
import brand1 from "../../../assets/Images/BuisnessOwnerC1.png";
import brand2 from "../../../assets/Images/BuisnessOwnerC2.png";
import brand3 from "../../../assets/Images/BuisnessOwnerC3.png";
import gif from "../../../assets/Images/Untitled design (4).gif";
import Test from "../../Common/test";
import { NavLink } from "react-router-dom";
const BuisnessOwner = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const noMargin = "true";
  const cardData = [
    {
      title: "One-Time Investment/Plug & Play Solution",
      para: "rent your billboard Inventory and earn passive income.",
    },
    {
      title: "Generate extra Revenue",
      para: " helps you utilize your digital screens to promote your brand and products, and generate revenue by selling your advertising space to third parties.",
    },
    {
      title: "Wow Factor",
      para: "Visual enhancement of dead asset, resulting in increased customer engagement.",
    },
  ];
  React.useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <Grid container className="positionRelative heroImageGrid">
        <Grid item xs={12} md={12} sx={{ height: "100%", width: "100%" }}>
          <img src={HeroImage} className="heroSectionClass widh100" />
        </Grid>
        <Grid
          item
          xs={12}
          className="positionAbsolute BuisnessOwnerHeroSectionDiv"
        >
          <Grid container className="heroTextgap">
            <Grid item xs={12}>
              <Typography className="BuisnessOwnerHeadingFontSize">
                Transform Your Property’s Glass Door or Window Into A Digital
                Billboard & generate extra income
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div>
                <Typography className=" heroSectionParaMarginBottom BuisnessOwnerParaFontSize2">
                  Deliver more value to your clients by building integrated
                  campaigns that
                </Typography>
                <Typography className=" BuisnessOwnerParaFontSize2">
                  include PDOOH media and Other Value added Services.
                </Typography>
              </div>
            </Grid>
            <Box
              sx={{
                textAlign: "center",
                width: "100%",
              }}
            >
              <NavLink to="/contact_us" style={{ textDecoration: "none" }}>
                <Button className="heroSectionButton">Request A Demo</Button>
              </NavLink>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Container maxWidth="xl">
        <Typography
          className="heading2 textAlignCentre"
          sx={{ marginTop: "2rem", marginBottom: "2rem" }}
        >
          A platform to ensure optimum utilization of your Infrastructure
        </Typography>
        <Typography className="DarGrayColorTypography fontSize18px textAlignCentre">
          PostMyAd helps you to not only utilize the additional infrastructure
          but to generate business and extra income using the same.
        </Typography>
        <Grid
          container
          //   sx={{ padding: "4rem 0rem" }}
          className="widh90  cardGapcontainer marginTop100px marginBottom100px"
          justifyContent="space-between"
        >
          {cardData.map((cd) => (
            <Grid
              className="card "
              item
              xs={12}
              md={3.8}
              sx={{
                padding: "4rem 3rem",
              }}
            >
              <Grid
                container
                className="cardGap"
                justifyContent="center"
                alignItems="center"
                direction="column"
                // gap="2rem"
              >
                <Typography
                  className={
                    isSm
                      ? "cardTypographyHeading fontSize20px Fontcolor2f3237"
                      : "cardTypographyHeading fontSize20px marginBottom20px Fontcolor2f3237"
                  }
                >
                  {cd.title}
                </Typography>
                <Typography
                  className={
                    isSm
                      ? "cardTypographyParagraph widh90"
                      : "cardTypographyParagraph"
                  }
                  sx={{ marginTop: "1rem" }}
                >
                  {cd.para}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid
          container
          className={
            isMd ? "widh90 marginTop50px gap20px" : "widh90 marginTop100px"
          }
          justifyContent="space-between"
        >
          <Grid item xs={12} md={5.5}>
            <Grid
              container
              gap="3rem"
              direction="column"
              justifyContent="center"
              sx={{ height: "100%" }}
            >
              <Typography className="heading1">
                {" "}
                easily manage your billboard inventory on the platform
              </Typography>
              <Typography className="subHeading1">
                Manage pricing, availability and inventory details quickly and
                easily, all in one place. postmyad Media Owner Dashboard offers
                a single source of truth for your inventory — you`ll never
                double-book a billboard again.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={5.5}>
            <img src={brand1} alt="agencyCardImage" className="widh100 " />
          </Grid>
        </Grid>
        <Grid
          container
          className={
            isMd ? "widh90 marginTop50px gap20px" : "widh90 marginTop100px"
          }
          justifyContent="space-between"
        >
          <Grid item xs={12} md={5.5} order={{ xs: 2, md: 1 }}>
            <img src={brand3} alt="agencyCardImage" className="widh100 " />
          </Grid>
          <Grid item xs={12} md={5.5} order={{ xs: 1, md: 1 }}>
            <Grid
              container
              gap="3rem"
              direction="column"
              justifyContent="center"
              sx={{ height: "100%" }}
            >
              <Typography className="heading1">
                {" "}
                Cost-effective advertising with measurable ROI
              </Typography>
              <Typography className="subHeading1">
                requires minimum time and money and tracks the effectiveness of
                your ads and campaigns
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          className={
            isMd ? "widh90 marginTop50px gap20px" : "widh90 marginTop100px"
          }
          justifyContent="space-between"
        >
          <Grid item xs={12} md={5.5}>
            <Grid
              container
              gap="3rem"
              direction="column"
              justifyContent="center"
              sx={{ height: "100%" }}
            >
              <Typography className="heading1">
                {" "}
                magnetize more valuable brands
              </Typography>
              <Typography className="subHeading1">
                Showcase your unique inventory with a beautifully designed
                interface that enables easy discovery and inclusion in campaigns
                from national brands and the world’s fastest growing start-ups.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={5.5}>
            <img src={brand2} alt="agencyCardImage" className="widh100 " />
          </Grid>
        </Grid>
        <Typography
          className="heading1 textAlignCentre "
          sx={{ margin: "10rem 0 5rem 0" }}
        >
          how it works?
        </Typography>

        <Grid
          container
          sx={{ padding: "4rem 0rem" }}
          className="widh90  cardGapcontainer"
          justifyContent="space-between"
        >
          <Grid
            className="card "
            item
            xs={12}
            md={3.8}
            sx={{
              padding: "4rem 3rem",
            }}
          >
            <Grid
              container
              className="cardGap"
              justifyContent="center"
              alignItems="center"
              direction="column"
              // gap="2rem"
            >
              <div>
                <Typography className="fontSize48px fontWeight600">
                  1
                </Typography>
              </div>
              <Typography
                className={
                  isSm
                    ? "cardTypographyHeading fontSize20px Fontcolor2f3237"
                    : "cardTypographyHeading fontSize20px marginBottom20px Fontcolor2f3237"
                }
              >
                install
              </Typography>
              <Typography
                className={
                  isSm
                    ? "cardTypographyParagraph widh90"
                    : "cardTypographyParagraph"
                }
                sx={{ marginTop: "1rem" }}
              >
                One time installation of our proprietary hardware.
              </Typography>
            </Grid>
          </Grid>
          <Grid
            className="card "
            item
            xs={12}
            md={3.8}
            sx={{
              padding: "4rem 3rem",
            }}
          >
            <Grid
              container
              className="cardGap"
              justifyContent="center"
              alignItems="center"
              direction="column"
              // gap="2rem"
            >
              <div>
                <Typography className="fontSize48px fontWeight600">
                  2
                </Typography>
              </div>
              <Typography
                className={
                  isSm
                    ? "cardTypographyHeading fontSize20px Fontcolor2f3237"
                    : "cardTypographyHeading fontSize20px marginBottom20px Fontcolor2f3237"
                }
              >
                lease out
              </Typography>
              <Typography
                className={
                  isSm
                    ? "cardTypographyParagraph widh90"
                    : "cardTypographyParagraph"
                }
                sx={{ marginTop: "1rem" }}
              >
                Lease out the screen for advertisement display.
              </Typography>
            </Grid>
          </Grid>
          <Grid
            className="card "
            item
            xs={12}
            md={3.8}
            sx={{
              padding: "4rem 3rem",
            }}
          >
            <Grid
              container
              className="cardGap"
              justifyContent="center"
              alignItems="center"
              direction="column"
              // gap="2rem"
            >
              <div>
                <Typography className="fontSize48px fontWeight600">
                  3
                </Typography>
              </div>
              <Typography
                className={
                  isSm
                    ? "cardTypographyHeading fontSize20px Fontcolor2f3237"
                    : "cardTypographyHeading fontSize20px marginBottom20px Fontcolor2f3237"
                }
              >
                make profit
              </Typography>
              <Typography
                className={
                  isSm
                    ? "cardTypographyParagraph widh90"
                    : "cardTypographyParagraph"
                }
                sx={{ marginTop: "1rem" }}
              >
                Recurring revenue from screen rentals.
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container sx={{ paddingTop: "10rem" }}>
          <Grid item xs={12}>
            <Typography className="heading1 textAlignCentre">
              Do You Want To Sell Billboard Inventory In Online?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div style={{ textAlign: "center" }}>
              <img src={gif} alt="POST MY AD" style={{ width: "80%" }} />
            </div>
          </Grid>
        </Grid>
      </Container>
      <Test noMargin />
    </>
  );
};

export default BuisnessOwner;
